import React, { Component, useState } from 'react';
import style from './mapbox-map.module.scss';
import { StaticQuery, graphql } from 'gatsby';
import { WindowSizeContext } from '../context/WindowSizeContext';
import { token } from '../token';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
require('mapbox-gl/dist/mapbox-gl.css');

let LMap, GeoJSONLayer, ZoomControl, MapboxGeocoder;

const MapControls = ({ link, title, icon, change, activeLink }) => {
  const [showHint, setShowHint] = useState(false);
  return (
    <li
      key={link}
      onClick={() => change(link)}
      onMouseEnter={() => setShowHint(true)}
      onFocus={() => setShowHint(true)}
      onMouseLeave={() => setShowHint(false)}
      onBlur={() => setShowHint(false)}
      className={activeLink === link ? style['active'] : ''}
    >
      <img alt={title || ''} src={require(`../${icon}`)} className="likeButton" />
      <span
        className={style['hint']}
        style={{
          transform: `translate(${
            Boolean(showHint) ? 'calc(100% + 7px), -50%' : 'calc(130% + 7px), -50%'
          })`,
          opacity: Boolean(showHint) ? 1 : 0
        }}
      >
        {title || ''}
      </span>
    </li>
  );
};

export class MapboxMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };

    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;

    const ReactMapboxGl = require('react-mapbox-gl');
    MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder');

    GeoJSONLayer = ReactMapboxGl.GeoJSONLayer;
    LMap = ReactMapboxGl.Map({
      accessToken: token,
      minZoom: this.props.minZoom || 11.8,
      maxZoom: this.props.maxZoom || 17,
      scrollZoom: true
    });
    ZoomControl = ReactMapboxGl.ZoomControl;
    this.forceUpdate();
  }
  componentWillUnmount() {
    this._mounted = false;
  }

  onMoveEnd = map => {
    const zoom = map.getZoom();
    const { lat, lng } = map.getCenter();

    const bounds = map.getBounds();
    const southWest = bounds._sw;
    const northEast = bounds._ne;

    const polygon = [
      [southWest.lng, southWest.lat],
      [southWest.lng, northEast.lat],
      [northEast.lng, northEast.lat],
      [northEast.lng, southWest.lat]
    ];

    this.props.onVisibleAreaChanged &&
      this.props.onVisibleAreaChanged({
        polygon: polygon,
        zoom: zoom,
        center: [lat, lng]
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.width !== prevProps.width) {
      this.map && this.map.resize();
    }
  }

  onStyleLoad = map => {
    this.map = map;

    if (this._mounted) {
      const geocoder = new MapboxGeocoder({
        accessToken: token,
        countries: 'de',
        language: 'de',
        placeholder: 'Suche nach Ort oder Adresse',
        marker: true,
        flyTo: true,
        mapboxgl: require('mapbox-gl'),
        bbox: [5.7882884978608615, 50.98233699274803, 7.14597511257125, 51.4616957441082]
      });
      geocoder.on('clear', e => {
        const [lat, lng] = this.props.center;
        map.jumpTo({ center: [lng, lat], zoom: 6 });
        //flyTo with animation seams broken
      });
      geocoder.on('result', e => {
        const input = geocoder.container.querySelector('input');
        if (input) input.blur();
      });
      // map.addControl(geocoder, 'top-right');
      if (this.props.inputRef && !this.props.inputRef.current.childElementCount) {
        this.props.inputRef.current.appendChild(geocoder.onAdd(map));
      }

      const bounds = map.getBounds();
      const southWest = bounds._sw;
      const northEast = bounds._ne;

      const polygon = [
        [southWest.lng, southWest.lat],
        [southWest.lng, northEast.lat],
        [northEast.lng, northEast.lat],
        [northEast.lng, southWest.lat]
      ];

      this.props.onVisibleAreaChanged &&
        this.props.onVisibleAreaChanged({
          polygon: polygon
        });
    }
  };

  render() {
    const [lat, lng] = this.props.center;

    const center = { lat: lat, lng: lng };
    const zoom = [this.props.zoom];

    return (
      <WindowSizeContext.Consumer>
        {({ viewport }) => {
          return (
            <StaticQuery
              query={graphql`
                {
                  allDataJson(filter: { page: { eq: "map" } }) {
                    edges {
                      node {
                        map {
                          styles {
                            link
                            icon
                            title
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={data => (
                <>
                  <aside className={style.mapControls}>
                    <ul>
                      {data.allDataJson.edges[0].node.map.styles.map(item => {
                        return (
                          <MapControls
                            key={item.title}
                            {...item}
                            change={this.props.onMapStyleChange}
                            activeLink={this.props.mapStyle.link}
                          />
                        );
                      })}
                    </ul>
                  </aside>
                  <div
                    className={'mapbox-container ' + style.mapboxContainer}
                    style={{
                      height: this.props.height,
                      width: this.props.width,
                      ...this.props.style
                    }}
                  >
                    {LMap && ( // prevent Mapbox from being server side rendered
                      <LMap
                        animate={true}
                        center={center}
                        zoom={zoom}
                        style={this.props.mapStyle.link}
                        className={style.map}
                        dragRotate={false}
                        pitchWithRotate={false}
                        touchZoomRotate={false}
                        dragPan={false}
                        maxBounds={this.props.maxBounds}
                        // onMoveEnd={this.onMoveEnd}
                        onZoomEnd={this.onMoveEnd}
                        onDragEnd={this.onMoveEnd}
                        // onClick={(_, e) => console.log([e.lngLat.lng, e.lngLat.lat])}
                        onStyleLoad={this.onStyleLoad}
                      >
                        <ZoomControl
                          position="bottom-left"
                          style={{
                            bottom: 50
                          }}
                        />
                        {this.props.mapFeatures &&
                          this.props.mapFeatures.map((e, i) => {
                            return (
                              <GeoJSONLayer
                                key={i} // there could probably be a better index
                                // fillPaint={{
                                //   'fill-opacity': e.fillProperties['fill_opacity'],
                                //   'fill-color': e.fillProperties['fill_color']
                                // }}
                                linePaint={{
                                  'line-color': 'rgb(0, 149, 219)',
                                  'line-width': 3
                                }}
                                data={{
                                  type: 'Feature',
                                  geometry: {
                                    type: 'Polygon',
                                    coordinates: [e.shape]
                                  }
                                }}
                              />
                            );
                          })}
                        {this.props.children}
                      </LMap>
                    )}
                  </div>
                </>
              )}
            />
          );
        }}
      </WindowSizeContext.Consumer>
    );
  }
}
