import React, { Component } from 'react';

import style from './contact.module.scss';
import { Button } from '../components/button';
import { Spinner } from '../components/spinner';
import { sendFeedback } from '../api/feedback';
import { Recaptcha } from '../components/recaptcha';

export class Contact extends Component {
  constructor(props) {
    super(props);

    const { user = {} } = props;
    const { email, username } = user;

    this.state = {
      subject: '',
      text: '',
      email,
      name: username,
      company: '',
      error: null,
      isSending: false,
      success: false,
      captchaToken: undefined
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.state.captchaToken === null) {
      this.setState({
        error: 'Das Captcha ist abgelaufen. Bitte benutzen Sie es erneut.'
      });
      return;
    } else if (this.state.captchaToken === undefined) {
      this.setState({
        error: 'Bitte benutzen Sie das Captcha-Element.'
      });
      return;
    }

    this.setState({ error: null, isSending: true });
    sendFeedback({
      subject: this.state.subject,
      text: this.state.text,
      email: this.state.email,
      name: this.state.name,
      company: this.state.company,
      captchaToken: this.state.captchaToken
    })
      .then(([_, err]) => {
        if (err) {
          return this.setState({ isSending: false, success: false, error: err.message });
        }

        this.setState({ success: true, isSending: false, error: null });
      })
      .catch(e => {
        console.error(e);

        this.setState({
          isSending: false,
          success: false,
          error: 'Something unexpected happened!'
        });
      });
  };

  render() {
    const { subject, text, email, name, company, error, isSending, success } = this.state;

    return (
      <div className={style.contact}>
        <h2>Schreiben an Thüringen packt's</h2>
        {success ? (
          <p>Danke für Ihre Nachricht!</p>
        ) : (
          <form onSubmit={this.onSubmit}>
            <div className={style.contactGroup}>
              <label>
                <input
                  required
                  placeholder="Betreff *"
                  type="text"
                  name="subject"
                  value={subject}
                  onChange={this.onChange}
                />
              </label>
              <br />
              <label>
                <input
                  required
                  placeholder="Name *"
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                />
              </label>
              <br />
              <label>
                <input
                  placeholder="Unternehmen"
                  type="text"
                  name="company"
                  value={company}
                  onChange={this.onChange}
                />
              </label>
              <br />
              <label>
                <input
                  required
                  placeholder="E-Mail *"
                  type={'email'}
                  name="email"
                  value={email}
                  onChange={this.onChange}
                />
              </label>
              <br />
              <label>
                <textarea
                  required
                  name="text"
                  placeholder={'Ihre Nachricht'}
                  value={text}
                  onChange={this.onChange}
                />
              </label>
              <br />
              <Recaptcha
                style={{ marginBottom: '12px' }}
                elementID={'feedback-captcha'}
                verifyCallback={token => {
                  this.setState({ captchaToken: token });
                }}
                expiredCallback={() => {
                  this.setState({ captchaToken: null });
                }}
              />

              {error && <div className="formHint">{error}</div>}
            </div>
            <div>
              <Button
                style={{ float: 'right', padding: '5px 10px' }}
                type="submit"
                modifier={isSending && 'loading'}
                disabled={isSending}
              >
                {isSending && <Spinner />}
                Absenden
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}
