import React, { Component } from 'react';
import Layout from '../components/layout';
import { Link } from '@reach/router';
import { Sidebar } from '../components/sidebar';
import { Arrow } from '../components/arrow';
import sidebarStyle from '../components/sidebar.module.scss';
import { CloseButton } from '../components/closeButton';
import { getPosts, likePost, blamePost, contactPost } from '../api';
import { PostCreate } from '../components/post-create';
import { MapboxMap } from '../components/mapbox-map';
import { Tile } from '../components/post-tile';
import tileStyle from '../components/tile.module.scss';
import { PostDetail } from '../components/post-detail';
import { Footer } from '../components/footer';
import filterStyle from '../components/filter.module.scss';
import { graphql, StaticQuery, navigate } from 'gatsby';
import { Button } from '../components/button';
import withLocation from '../components/with-location';
import { validatePost, setPostVisibility } from '../api/post';
import WarningPost from '../components/warning-post';
import { containsAll, add, get } from '../api/localstorage-provider';
import PopupDialog from '../components/popup-dialog';
import { UserContext } from '../context/UserContext';
import { Recaptcha } from '../components/recaptcha';

let Marker;

class PostList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      filters: Object.keys(props.pageContext.categories),
      error: undefined,
      openDetail: undefined,
      blamedPosts: new Set(),
      blamePost: undefined,
      contactPosts: new Set(),
      contactPost: undefined,
      initial: true,
      hasFilterBeenUsed: false,
      createOpen: false,
      blameCaptchaToken: undefined,
      blameCaptchaError: undefined,
      contactCaptchaToken: undefined,
      contactCaptchaError: undefined,
      isSafari: false,
      hoveredMarker: undefined,
      updateMapCenter: false,
      updateZoom: false,
      interaction: true
    };

    this.hoverTimeout = undefined;
  }

  componentDidMount() {
    const ReactMapboxGl = require('react-mapbox-gl');
    Marker = ReactMapboxGl.Marker;

    const posted = new Set(get('posted') || []);
    posted.add(this.props.pageContext.parent);
    add('posted', [...posted]);

    if (this.props.search.token !== undefined) {
      validatePost({ slug: this.props.search.slug, token: this.props.search.token }).then(() => {
        this.getPostList();
      });
    } else {
      this.getPostList();
    }

    this.setState({
      isSafari:
        navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0
    });
  }

  componentWillUnmount() {
    clearTimeout(this.hoverTimeout);
  }
  toggleAllFilter = () => {
    this.state.filters.length > 0
      ? this.setState({ hasFilterBeenUsed: false, filters: [] })
      : this.setState({
          hasFilterBeenUsed: false,
          filters: Object.keys(this.props.pageContext.categories)
        });
  };
  toggleFilter = categoryName => {
    if (this.state.hasFilterBeenUsed) {
      this.setState({
        filters: this.state.filters.includes(categoryName)
          ? this.state.filters.filter(e => e !== categoryName)
          : [...this.state.filters, categoryName]
      });
      return;
    }

    this.setState({ hasFilterBeenUsed: true, filters: [categoryName] });
  };

  getPostList = () => {
    this.setState({
      updateZoom: true,
      updatePosition: true
    });
    getPosts({ variant: this.props.pageContext.parent }).then(([posts, error]) => {
      if (posts) {
        this.setState({ posts: posts.items || [], error });
        if (this.state.openDetail) {
          this.setState({ openDetail: this.state.posts[this.state.openIndex] });
        }

        if (this.props.search.slug !== undefined && this.state.initial === true) {
          const post = posts.items.find(({ slug }) => slug === this.props.search.slug);

          if (Boolean(post)) {
            this.setState({ openDetail: post, initial: false });
          }
        }
      }
    });
  };
  hoverMarker = slug => event => {
    clearTimeout(this.hoverTimeout);
    this.hoverTimeout = setTimeout(() => {
      // TODO: move this into state instead of doing it by hand
      const element = document.getElementById(`list-${slug}`);
      element.style.outline = '3px solid #0095db';
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, 1000);
  };

  hoverEndMarker = slug => event => {
    clearTimeout(this.hoverTimeout);
    const element = document.getElementById(`list-${slug}`);
    element.style.outline = 'none';
  };

  openDetail = (item, index, interaction) => e => {
    if (e.target.classList && e.target.classList.contains('likeButton')) {
      if (interaction) this.likePost({ slug: item.slug });
    } else {
      this.setState({ openDetail: item });
      this.setState({ openIndex: index });
    }
    this.setState({ interaction: interaction });
  };

  likePost = ({ slug }) => {
    likePost({ slug: slug }).then(([post, error]) => {
      if (!error) {
        this.getPostList();
      }
    });
  };

  blamePost = ({ slug }) => {
    this.setState({ blamePost: slug });
  };

  contactPost = ({ slug }) => {
    this.setState({ contactPost: slug });
  };

  submitBlame = event => {
    event.preventDefault();

    const reasonField = event.target.querySelector('[name="reason"]');
    const emailField = event.target.querySelector('[name="email"]');

    const formData = new FormData(event.target);

    if (this.state.blameCaptchaToken === null) {
      this.setState({
        blameCaptchaError: 'Das Captcha ist abgelaufen. Bitte benutzen Sie es erneut.'
      });
      return;
    } else if (this.state.blameCaptchaToken === undefined) {
      this.setState({
        blameCaptchaError: 'Bitte benutzen Sie das Captcha-Element.'
      });
      return;
    }

    if (!Boolean(formData.get('reason'))) {
      reasonField.classList.add('error');
      return;
    }

    const sendData = {
      slug: this.state.blamePost,
      email: formData.get('email'),
      reason: formData.get('reason'),
      captchaToken: this.state.blameCaptchaToken
    };

    this.setState({ isBlaming: true, blameCaptchaError: undefined });
    blamePost(sendData)
      .then(([post, error]) => {
        if (!error) {
          reasonField.classList.remove('error');
          reasonField.value = '';
          emailField.value = '';

          const blamedPosts = this.state.blamedPosts;
          blamedPosts.add(this.state.blamePost);
          this.setState({
            blamedPosts: blamedPosts,
            blamePost: undefined,
            isBlaming: undefined,
            blameCaptchaToken: undefined,
            blameCaptchaError: undefined
          });
        } else {
          this.setState({
            blameCaptchaError: error.message,
            isBlaming: undefined
          });
        }
      })
      .catch(console.error);
  };

  submitContact = event => {
    event.preventDefault();

    const messageBodyField = event.target.querySelector('[name="messageBody"]');
    const emailField = event.target.querySelector('[name="email"]');
    const phoneField = event.target.querySelector('[name="phone"]');

    const formData = new FormData(event.target);

    if (this.state.contactCaptchaToken === null) {
      this.setState({
        contactCaptchaError: 'Das Captcha ist abgelaufen. Bitte benutzen Sie es erneut.'
      });
      return;
    } else if (this.state.contactCaptchaToken === undefined) {
      this.setState({
        contactCaptchaError: 'Bitte benutzen Sie das Captcha-Element.'
      });
      return;
    }

    if (!Boolean(formData.get('messageBody'))) {
      messageBodyField.classList.add('error');
      return;
    }

    const sendData = {
      slug: this.state.contactPost,
      email: formData.get('email'),
      phone: formData.get('phone'),
      messageBody: formData.get('messageBody'),
      captchaToken: this.state.contactCaptchaToken
    };

    this.setState({ isContacting: true, blameCaptchaError: undefined });
    contactPost(sendData)
      .then(([post, error]) => {
        if (!error) {
          messageBodyField.classList.remove('error');
          messageBodyField.value = '';
          emailField.value = '';
          phoneField.value = '';

          const contactPosts = this.state.contactPosts;
          contactPosts.add(this.state.contactPost);
          this.setState({
            contactPosts: contactPosts,
            contactPost: undefined,
            isContacting: undefined,
            contactCaptchaToken: undefined,
            contactCaptchaError: undefined
          });
        } else {
          this.setState({
            contactCaptchaError: error.message,
            isContacting: undefined
          });
        }
      })
      .catch(console.error);
  };

  toggleVisibility = (item, user) => {
    if (user.loggedIn && user.roles.includes('ROLE_ADMIN')) {
      setPostVisibility({
        slug: item.slug,
        visible: !item.visible
      }).then(([post, error]) => {
        if (!error) {
          this.getPostList();
        }
      });
    }
  };

  isVisible = e => {
    const { categories } = this.props.pageContext;
    return categories[e.category] !== undefined && this.state.filters.includes(e.category);
  };

  render() {
    const {
      colorCode,
      textHighlightColor,
      title,
      // quarterTitle,
      // letter,
      parent,
      previous,
      next,
      map,
      shape,
      categories,
      postCreateContent,
      needs
    } = this.props.pageContext;

    const markers = {};
    const noFilters = this.state.filters.length === 0;
    const showDialog = Array.isArray(needs) && needs.length > 0 && !containsAll('posted', needs);

    const mapModule = postCreateContent.find(function(el) {
      return el.type === 'module-post-map';
    });
    const { isSafari } = this.state;

    return (
      <Layout>
        <MapboxMap
          center={[map.lat, map.lng]}
          zoom={map.zoom}
          minZoom={map.minZoom}
          maxZoom={map.maxZoom}
          mapStyle={map.style}
          mapFeatures={shape}
          updateZoom={this.state.updateZoom}
          updatePosition={this.state.updateMapCenter}
        >
          {this.state.posts
            .filter(
              ({ coordinates, category }) =>
                !!coordinates &&
                coordinates.latitude &&
                coordinates.longitude &&
                categories[category] !== undefined &&
                this.state.filters.includes(category)
            )
            .map(item => {
              markers[item.slug] = (
                <Marker
                  anchor={'bottom'}
                  coordinates={{
                    lat: item.coordinates.latitude,
                    lng: item.coordinates.longitude
                  }}
                  onMouseEnter={this.hoverMarker(item.slug)}
                  onMouseLeave={this.hoverEndMarker(item.slug)}
                  key={`marker-${item.slug}`}
                  onClick={this.openDetail(item)}
                  style={{
                    cursor: 'pointer',
                    opacity: item.visible ? 1 : 0.5,
                    filter: isSafari ? 'none' : `grayscale(${item.visible ? 0 : 1})`
                  }}
                  className={this.state.hoveredMarker === item.slug ? 'active-marker' : ''}
                >
                  <img
                    src={
                      this.state.hoveredMarker === item.slug &&
                      categories[item.category].marker.activeFile
                        ? require(`../images/${categories[item.category].marker.activeFile}`)
                        : require(`../images/${categories[item.category].marker.file}`)
                    }
                    alt="Marker"
                  />
                </Marker>
              );

              return markers[item.slug];
            })}
        </MapboxMap>
        <Sidebar
          className={this.state.createOpen ? sidebarStyle.forceOverflow : undefined}
          render={currentSideBarState => {
            const baseTileStyle = `${tileStyle.tile} ${tileStyle[currentSideBarState]}`;
            return (
              <>
                <div className={sidebarStyle.buttonBar}>
                  {previous && (
                    <Link to={previous}>
                      <Arrow.Left aria-label="Vorherige Seite" />
                    </Link>
                  )}
                  {next && (
                    <Link to={next}>
                      <Arrow.Right aria-label="Nächste Seite" />
                    </Link>
                  )}
                  <Link to={parent} title="Zurück">
                    <CloseButton className={sidebarStyle.closePage} />
                  </Link>
                </div>
                <h2
                  style={{
                    backgroundColor: colorCode,
                    marginBottom: '22px',
                    padding: '1rem 1rem 0.7rem',
                    color: textHighlightColor || '#000'
                  }}
                >
                  {title} | <b>Beiträge erkunden</b>
                </h2>
                <CategoryFilter
                  noFilters={noFilters}
                  categories={categories}
                  filters={this.state.filters}
                  onCategoryToggle={this.toggleFilter}
                  onCategoryToggleAll={this.toggleAllFilter}
                  colorCode={colorCode}
                  textHighlightColor={textHighlightColor}
                  posts={this.state.posts}
                />
                <StaticQuery
                  query={graphql`
                    query {
                      startPage: allDataJson(filter: { page: { eq: "start" } }) {
                        edges {
                          node {
                            content {
                              type
                              endDate
                            }
                          }
                        }
                      }
                    }
                  `}
                  render={data => {
                    const countdown =
                      data &&
                      data.startPage &&
                      data.startPage.edges &&
                      data.startPage.edges[0] &&
                      data.startPage.edges[0].node &&
                      Array.isArray(data.startPage.edges[0].node.content) &&
                      data.startPage.edges[0].node.content.find(
                        el => el && el.type === 'module-countdown'
                      );

                    // Hint: change this to hide/show post create
                    const showPostCreate =
                      Boolean(countdown) && Boolean(countdown.endDate)
                        ? new Date().getTime() <= new Date(countdown.endDate).getTime()
                        : true;

                    return (
                      <div key={'post-tile-wrapper'} className={tileStyle.tileWrapper}>
                        <UserContext.Consumer>
                          {user => {
                            const isAdmin = user.loggedIn && user.roles.includes('ROLE_ADMIN');

                            return [
                              showPostCreate && (
                                <PostCreate
                                  variant={parent}
                                  center={[map.lat, map.lng]}
                                  colorCode={colorCode}
                                  textHighlightColor={textHighlightColor}
                                  sidebar={currentSideBarState}
                                  key={'post-create-button'}
                                  shape={shape}
                                  onPostCreated={this.getPostList}
                                  content={postCreateContent}
                                  onToggleOpenState={open => this.setState({ createOpen: open })}
                                />
                              )
                            ]
                              .concat(
                                this.state.posts.filter(this.isVisible).map((item, index) => {
                                  const id = `list-${item.slug}`;
                                  let renderList = [
                                    <Tile
                                      key={id}
                                      id={id}
                                      post={item}
                                      className={
                                        baseTileStyle +
                                        (item.visible || isSafari ? '' : ` ${tileStyle.hidden}`)
                                      }
                                      onClick={this.openDetail(item, index, showPostCreate)}
                                      category={categories[item.category]}
                                      like={
                                        showPostCreate
                                          ? () => this.likePost({ slug: item.slug })
                                          : () => {}
                                      }
                                      toggleVisibility={() => this.toggleVisibility(item, user)}
                                      isAdmin={isAdmin}
                                      onMouseEnter={() =>
                                        this.setState({
                                          updateZoom: false,
                                          hoveredMarker: item.slug
                                        })
                                      }
                                      onMouseLeave={() =>
                                        this.setState({
                                          hoveredMarker: undefined
                                        })
                                      }
                                      interaction={showPostCreate}
                                    />
                                  ];
                                  if ((index + 1) % 10 === 0 && showPostCreate) {
                                    renderList.push(
                                      <PostCreate
                                        variant={parent}
                                        center={[map.lat, map.lng]}
                                        colorCode={colorCode}
                                        textHighlightColor={textHighlightColor}
                                        sidebar={currentSideBarState}
                                        key={`post-create-button-list-${index}`}
                                        shape={shape}
                                        onPostCreated={this.getPostList}
                                        content={postCreateContent}
                                        onToggleOpenState={open =>
                                          this.setState({ createOpen: open })
                                        }
                                      />
                                    );
                                  }
                                  return renderList;
                                })
                              )
                              .filter(Boolean);
                          }}
                        </UserContext.Consumer>
                      </div>
                    );
                  }}
                />
              </>
            );
          }}
        />
        {this.state.openDetail && categories[this.state.openDetail.category] !== undefined && (
          <PostDetail
            {...this.state.openDetail}
            categoryObj={categories[this.state.openDetail.category]}
            onClose={() => {
              this.setState({ openDetail: undefined });
            }}
            interaction={this.state.interaction}
            onLike={() => {
              this.likePost({ slug: this.state.openDetail.slug });
            }}
            onBlame={() => {
              this.blamePost({ slug: this.state.openDetail.slug });
            }}
            onContact={() => {
              this.contactPost({ slug: this.state.openDetail.slug });
            }}
            blamed={this.state.blamedPosts.has(this.state.openDetail.slug)}
            contacted={this.state.contactPosts.has(this.state.openDetail.slug)}
            map={{
              style:
                (mapModule && mapModule.map && mapModule.map.style) ||
                'mapbox://styles/cheffen/cjyk5thfn07yu1dm0v66sfvbg'
            }}
          />
        )}
        {Boolean(this.state.blamePost) && (
          <PopupDialog
            onClose={() =>
              this.setState({
                blamePost: undefined,
                isBlaming: undefined,
                blameCaptchaToken: undefined,
                blameCaptchaError: undefined
              })
            }
            image={require('../images/mail.svg')}
          >
            <h2>Diesen Beitrag Melden</h2>
            <form onSubmit={this.submitBlame}>
              <label>Warum melden?</label>
              <textarea name="reason" />
              <label>E-Mail-Adresse</label>
              <input type="text" name="email" />
              <Recaptcha
                style={{
                  display: 'inline-block',
                  width: '100%'
                }}
                expiredCallback={() => {
                  this.setState({ blameCaptchaToken: null });
                }}
                verifyCallback={token => {
                  this.setState({ blameCaptchaToken: token });
                }}
              />
              {this.state.blameCaptchaError && (
                <span style={{ color: 'red', display: 'block' }}>
                  {this.state.blameCaptchaError}
                </span>
              )}
              <Button modifier={this.state.isBlaming && 'loading'} disabled={this.state.isBlaming}>
                Absenden
              </Button>
            </form>
          </PopupDialog>
        )}

        {Boolean(this.state.contactPost) && (
          <PopupDialog
            onClose={() =>
              this.setState({
                contactPost: undefined,
                isContacting: undefined,
                contactCaptchaToken: undefined,
                contactCaptchaError: undefined
              })
            }
            image={require('../images/mail.svg')}
          >
            <h2>Den Beitragsersteller kontaktieren</h2>
            <form onsubmit={this.submitContact}>
              <label>Sende eine Nachricht*</label>
              <textarea name="messageBody" required />
              <label>Deine E-Mailadresse*</label>
              <input type="text" name="email" required />
              <label>Deine Telefonnummer</label>
              <input type="text" name="phone" />
              <label style={{ display: 'flex', marginBottom: '20px' }}>
                <span
                  style={{
                    display: 'inline-block',
                    margin: '0 25px 0 0'
                  }}
                >
                  <input style={{ minWidth: '20px' }} type="checkbox" name="dataSec" required />
                </span>
                <span>
                  Ich habe die{' '}
                  <Link to="/sicherheitshinweise">Hinweise und Vorsichtsmaßnahmen</Link> gelesen.*
                  <br />
                  <br />
                  Mit dem Absenden dieser Nachricht erklärst Du dich mit den{' '}
                  <Link to="/imprint">AGB</Link> und den <Link to="/imprint">Nettiquetten</Link>{' '}
                  dieser Plattform einverstanden. Wir weisen explizit darauf hin, dass der/die
                  Empfängerin dieser Nachricht Deine E-Mail-Adresse sehen wird und Dir antworten
                  kann.
                </span>
              </label>
              <Recaptcha
                style={{
                  display: 'inline-block',
                  width: '100%'
                }}
                expiredCallback={() => {
                  this.setState({ contactCaptchaToken: null });
                }}
                verifyCallback={token => {
                  this.setState({ contactCaptchaToken: token });
                }}
              />
              {this.state.contactCaptchaError && (
                <span style={{ color: 'red', display: 'block' }}>
                  {this.state.contactCaptchaError}
                </span>
              )}
              <Button
                modifier={this.state.isContacting && 'loading'}
                disabled={this.state.isContacting}
              >
                Absenden
              </Button>
            </form>
          </PopupDialog>
        )}
        {showDialog && (
          <WarningPost
            onClose={() => {
              return navigate('/map/district/allgemeines');
            }}
          />
        )}
        <Footer />
      </Layout>
    );
  }
}

function CategoryFilter({
  noFilters,
  colorCode,
  textHighlightColor,
  categories,
  posts,
  filters,
  onCategoryToggle,
  onCategoryToggleAll
}) {
  const allActive = filters.length === Object.keys(categories).length;
  return (
    <ul className={filterStyle.list}>
      <li className={`${filterStyle.listItem}${allActive ? ` ${filterStyle.active}` : ''}`}>
        <button
          onClick={e => {
            e.preventDefault();
            onCategoryToggleAll();
          }}
          style={{
            color: allActive ? textHighlightColor : colorCode || '#000',
            backgroundColor: allActive ? colorCode : 'white'
          }}
        >
          {`Alle Beiträge (${posts.length})`}
        </button>
      </li>
      {Object.keys(categories).map(categoryName => {
        const active = noFilters ? false : filters.includes(categoryName);
        return (
          <li
            key={categoryName}
            className={`${filterStyle.listItem}${active ? ` ${filterStyle.active}` : ''}`}
          >
            <button
              onClick={e => {
                e.preventDefault();
                onCategoryToggle(categoryName);
              }}
              style={{
                color: active ? textHighlightColor : colorCode || '#000',
                backgroundColor: active ? colorCode : 'white'
              }}
            >
              {categoryName} (
              {
                posts.filter(function(post) {
                  return post.category === categoryName;
                }).length
              }
              )
            </button>
          </li>
        );
      })}
    </ul>
  );
}

export default withLocation(PostList);
