import React from 'react';
// import { Link } from 'gatsby';
import { Button } from './button';
import PopupDialog from './popup-dialog';

const WarningPost = ({ onClose }) => (
  <PopupDialog onClose={onClose} image={require('../images/mail.svg')}>
    <h2 style={{ fontWeight: 600, lineHeight: 1.3, fontSize: 28 }}>
      Bitte geben Sie zuerst einen Beitrag zum Gesamtareal des Entwicklungsgebietes ab!
      <br />
      Kennen Sie sich hier gut aus?
    </h2>
    {/* <p>oder</p>
    <p>
      <Link to="/map" onClick={onClose}>
        wechseln Sie direkt zum Hauptmenü
      </Link>
    </p> */}

    <Button onClick={onClose} modifier="darker">
      VERSTANDEN
    </Button>
  </PopupDialog>
);

export default WarningPost;
