import React, { Component } from 'react';
import { Info } from './info';
import postCreateStyle from '../../components/post-create.module.scss';
import style from './textinputs.module.scss';
import { isFunction } from '../../utils';

export class Description extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: props.value || ''
    };
  }

  onChange = e => {
    const description = e.target.value;
    this.setState({ description });
    if (isFunction(this.props.onChange)) this.props.onChange(description);
  };

  render() {
    const length = this.props.maxchars || 190;

    return (
      <>
        <h2>
          {!this.props.required && <span className={postCreateStyle.optionalTag}>optional</span>}
          {this.props.title}
        </h2>
        <div className={postCreateStyle.sectionLeft}>
          <textarea
            maxLength={length}
            onChange={this.onChange}
            className={style.textarea}
            value={this.state.description}
          />
          <span className={style.charcounter}>
            {this.state.description.length}/{length} Zeichen
          </span>
        </div>
        <div className={postCreateStyle.sectionRight}>
          <Info text={this.props.info} />
        </div>
      </>
    );
  }
}
