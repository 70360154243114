import React from 'react';
import layerStyle from './layer.module.scss';
import { CloseButton } from './closeButton';
import { Contact } from './contact';

const InfoLayer = ({ type, style, onClose }) => {
  return (
    <div className={layerStyle.infolayer} style={style}>
      <CloseButton
        onClick={e => {
          e.preventDefault();
          onClose();
        }}
        className={layerStyle.closeButton}
      />
      {type === 'info' ? (
        <div className={layerStyle.infotext}>
          <img
            src={require('../images/ws_logo.png')}
            alt="Wolfgang Tiefensee"
            style={{
              margin: '0px 0px 25px -10px',
              width: 114,
              display: 'block'
            }}
          />
          <h3>
            Liebe Thüringerinnen und Thüringer, <br />
            liebe Unternehmerinnen und Unternehmer,
          </h3>
          <p>
            die „Corona-Krise” trifft uns alle hart. Besonders getroffen sind davon zahlreiche
            Unternehmerinnen und Unternehmer vor Ort, die ihre Läden, Restaurants, Geschäfte mit
            viel Engagement, Einsatz und Leidenschaft aufgebaut haben. Der direkte Kundenkontakt,
            auf den sie zwingend angewiesen sind, ist derzeit nicht mehr möglich. Dennoch: Viele von
            ihnen stecken den Kopf jetzt nicht in den Sand - sie wissen sich auch jetzt zu helfen
            und gehen neue, kreative Wege, um weiterhin für ihre Kunden dazusein.
          </p>
          <p>
            Dabei können wir als Konsumenten und Verbraucher, als Thüringerinnen und Thüringer ihnen
            helfen - indem wir weiterhin lokale Produkte und Dienstleistungen in Anspruch nehmen,
            die weiterhin auch digital verfügbar sind. Diese Internetseite leistet dazu einen
            Beitrag - indem sie Angebote und Anbieter aus ganz Thüringen bündelt und übersichtlich
            präsentiert.
          </p>
          <p>
            Wir hoffen, dass wir damit in Zeiten von Ausgangsbeschränkungen, Kontaktsperren und
            Quarantänemaßnahmen ein wenig helfen können, dass Händler und Käufer, Handwerker und
            Kunden, Gastronomie und Gäster zumindest digital in Kontakt bleiben.
          </p>
          <p>
            Ich wünsche Ihnen alles Gute, bleiben Sie gesund <br />
            und kommen Sie gut durch diese Zeit.
          </p>
          <p>Ihr Wolfgang Tiefensee</p>
          <img
            src={require('../images/ws_logo.png')}
            alt="Wolfgang Tiefensee"
            style={{
              margin: '30px 0 0',
              width: 267,
              display: 'block'
            }}
          />
        </div>
      ) : type === 'contact' ? (
        <>
          <Contact />
        </>
      ) : (
        ''
      )}
    </div>
  );
};
export { InfoLayer };
