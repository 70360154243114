import React, { Fragment } from 'react';
import style from './post-detail.module.scss';
import { Container } from './container';
import { CloseButton } from './closeButton';
import StyledScrollbars from './styled-scrollbars';
import { Button } from './button';
import { Spinner } from './spinner';

export const PostDetail = ({
  onClose,
  onSend,
  content,
  image,
  address,
  title,
  weblink,
  professions,
  modifier,
  isSending = false,
  errors = [],
  modal = false
}) => {
  const isPreview = modifier === 'preview';

  return (
    <>
      <div
        className={`${style.postWrapper} ${modal ? style.postWrapperModal : ''}`}
        style={{ textAlign: 'initial' }}
      >
        <div className={style.post}>
          <div className={style.closeRow}>
            <CloseButton onClick={onClose} />
          </div>
          <div className={style.contentwrapper}>
            <StyledScrollbars>
              <Container modifier="contentWidthOverlay">
                <div className={style.colorWrapper}>
                  <div className={style.header}>
                    <div className={style.headerWrapper}>
                      {isPreview &&
                      image !== null &&
                      typeof image !== 'undefined' &&
                      image.toString().indexOf('/api') === -1 ? (
                        <img src={image} alt={title} />
                      ) : (
                        image && <img src={`${image}`} alt={title} />
                      )}
                    </div>
                  </div>
                  <section
                    className={`${style.noBorder} ${style.preview}`}
                    style={{
                      position: 'relative'
                    }}
                  >
                    <h2>{title}</h2>
                    {professions && (
                      <div style={{ marginBottom: '20px', fontWeight: 600 }}>
                        <ul>
                          {professions.map(category => (
                            <li key={category}>{category}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <a
                      className={style.linkButton}
                      href={weblink}
                      target="_blank"
                      rel="noopener noreferer"
                    >
                      Zum Unternehmensprofil
                    </a>
                    <div
                      style={{ marginBottom: '20px' }}
                      dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                    <div style={{ marginBottom: '30px' }} className={style.address}>
                      <h3>{title}</h3>
                      {address.street}
                      <br />
                      {address.zip} {address.city}
                    </div>
                  </section>
                </div>
              </Container>

              {isPreview ? (
                <Container
                  modifier="contentWidthOverlay"
                  style={{ textAlign: 'left', marginTop: 50, marginBottom: 40 }}
                >
                  <div style={{ color: 'red', marginBottom: 20 }}>
                    {errors.map((err, i) => {
                      return (
                        <Fragment key={i}>
                          <span>{err}</span>
                          <br />
                        </Fragment>
                      );
                    })}
                  </div>
                  <Button onClick={onClose} style={{ marginRight: 20, backgroundColor: 'gray' }}>
                    Bearbeiten / Ändern
                  </Button>
                  <Button onClick={onSend} modifier={isSending && 'loading'}>
                    {isSending && <Spinner />} Beitrag veröffentlichen
                  </Button>
                </Container>
              ) : (
                <></>
              )}
            </StyledScrollbars>
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * Adds the http:// prefix if it doesn't exist already
 * @param {string?} weblink
 * @return {string}
 */
function addHttpPrefix(weblink) {
  if (!weblink || weblink.length < 1) {
    return '';
  }

  if (!/^https?:\/\//.test(weblink)) {
    return `http://${weblink}`;
  }

  return weblink;
}
