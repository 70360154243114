import React, { Component, Fragment } from 'react';
import { Container } from './container';
import style from './post-create.module.scss';
import StyledScrollbars from './styled-scrollbars';
import { ModuleText } from './module-text';
import { Button } from './button';
import { Spinner } from './spinner';

import { Category } from '../modules/post/category';
import { Title } from '../modules/post/title';
import { Tags } from '../modules/post/tags';
import { Description } from '../modules/post/description';
import { MapModule } from '../modules/post/map';
import { Image } from '../modules/post/image';
import { Email } from '../modules/post/email';
import { Phone } from '../modules/post/phone';
import { Weblink } from '../modules/post/weblink';
import { CommentEmail } from '../modules/post/comment-email';
import { Alias } from '../modules/post/alias';

import { createPost, uploadImage } from '../api';
import { CloseButton } from './closeButton';
import { isFunction } from 'util';
import { Gender } from '../modules/post/gender';
import { Birthyear } from '../modules/post/birthyear';
import { City } from '../modules/post/city';
import { PostDetail } from './post-detail';
import PopupDialog from './popup-dialog';
import { Recaptcha } from './recaptcha';

const defaultState = {
  open: false,
  isSending: false,
  errors: [],
  coordinates: null,
  address: undefined,
  title: '',
  content: null,
  center: null,
  category: null,
  variant: '/offer',
  tags: [],
  image: null,
  image2: null,
  email: '',
  weblink: '',
  phone: '',
  alias: '',
  gender: null,
  birthyear: undefined,
  city: undefined,
  pointIsInShape: true,
  captchaToken: undefined,
  showVerificationMessage: false,
  showPreview: false,
  map: undefined,
  commentmail: undefined
};

export class PostCreate extends Component {
  constructor(props) {
    super(props);

    this.postWrapper = React.createRef();
    this.openButton = React.createRef();
    this.closeButton = React.createRef();
    defaultState.center = this.props.center || null;

    const birthyears = this.createOptionsList();

    this.state = {
      ...defaultState,
      birthyears: birthyears,
      birthyear: birthyears[0],
      map: props.content.find(e => e.type === 'module-post-map').map
    };

    // find the city module
    const cityModule = props.content.find(function(contentElement) {
      return contentElement.type === 'module-post-city';
    });

    // if this post-create includes a post create, use the first element in the dropdown list as default value
    if (cityModule !== undefined) {
      this.state.city = cityModule.dropdownValues[0];
    }
  }

  /**
   * @return {boolean} indicator, whether errors exist
   */
  checkFormErrors = () => {
    let errors = [];

    for (const item of this.props.content) {
      if (item.required) {
        switch (item.type) {
          case 'module-post-category':
            if (!Boolean(this.state.category)) {
              errors.push('Bitte wählen Sie eine Kategorie.');
            }
            break;
          case 'module-post-title':
            if (!Boolean(this.state.title)) {
              errors.push('Bitte geben Sie einen Titel an.');
            }
            break;
          case 'module-post-tags':
            if (this.state.tags.length === 0) {
              errors.push('Bitte wählen Sie mindestens ein Thema aus.');
            }
            break;
          case 'module-post-description':
            if (!Boolean(this.state.content)) {
              errors.push('Bitte geben Sie eine Beschreibung an.');
            }
            break;
          case 'module-post-map':
            if (!Boolean(this.state.coordinates)) {
              errors.push('Bitte geben Sie Ihre Adresse an.');
            }
            break;
          case 'module-post-image':
            if (!Boolean(this.state.image)) {
              errors.push('Bitte laden Sie ein Bild hoch.');
            }
            break;
          case 'module-post-image2':
            if (!Boolean(this.state.image2)) {
              errors.push('Bitte laden Sie ein Bild hoch.');
            }
            break;
          case 'module-post-email':
            if (!Boolean(this.state.email)) {
              errors.push('Bitte geben Sie Ihre E-Mail Adresse an.');
            }
            break;
          case 'module-post-alias':
            if (!Boolean(this.state.alias)) {
              errors.push('Bitte geben Sie einen Alias an.');
            }
            break;
          case 'module-post-gender':
            if (!Boolean(this.state.gender)) {
              errors.push('Bitte geben Sie Ihr Geschlecht an.');
            }
            break;
          case 'module-post-birthyear':
            if (!Boolean(this.state.birthyear)) {
              errors.push('Bitte geben Sie Ihr Geburtsjahr an.');
            }
            break;
          case 'module-post-city':
            if (!Boolean(this.state.city)) {
              errors.push('Bitte geben Sie Ihren Wohnort an.');
            }
            break;
          default:
            break;
        }
      }
    }

    if (this.state.captchaToken === null) {
      errors.push('Das Captcha ist abgelaufen. Versuchen Sie es bitte erneut.');
    } else if (this.state.captchaToken === undefined) {
      errors.push('Bitte benutzen Sie das Captcha-Element.');
    }

    if (errors.length > 0) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: [] });
    return true;
  };

  finishSend = ([post, error]) => {
    const newState = { ...defaultState };

    if (isFunction(this.props.onPostCreated)) {
      this.props.onPostCreated();
    }

    if (post) {
      if (Boolean(post.requiresDoubleOptIn)) {
        newState.showVerificationMessage = true;
        newState.open = true;
      }

      // TODO: setting the state might not be nessessary
      // because it's going to get closed anyways
      return this.setState({ ...newState }, () => {
        this.props.onToggleOpenState(false);
      });
    }

    this.setState({ errors: [error.message], isSending: false });
  };

  handleSend = () => {
    this.setState({ isSending: true, errors: [] });

    if (!this.checkFormErrors()) {
      this.setState({ isSending: false });
      return;
    }

    if (this.state.image) {
      uploadImage({ file: this.state.image, mode: 'post' })
        .then(([res, err]) => {
          if (err) {
            throw err;
          }

          return Promise.resolve(res.fileName);
        })
        .then(fileName => {
          if (this.state.image2) {
            uploadImage({ file: this.state.image2, mode: 'post' })
              .then(([res, err]) => {
                if (err) {
                  throw err;
                }

                return Promise.resolve(res.fileName);
              })
              .then(fileName2 => {
                createPost({ ...this.state, image: fileName, image2: fileName2 })
                  .then(([post, error]) => {
                    this.finishSend([post, error]);
                  })
                  .catch(err => {
                    console.error(err);
                    this.setState({ isSending: false });
                  });
              });
          } else {
            createPost({ ...this.state, image: fileName, image2: null })
              .then(([post, error]) => {
                this.finishSend([post, error]);
              })
              .catch(err => {
                console.error(err);
                this.setState({ isSending: false });
              });
          }
        });
    } else {
      createPost({ ...this.state, image: null, image2: null })
        .then(([post, error]) => {
          this.finishSend([post, error]);
        })
        .catch(err => {
          console.error(err);
          this.setState({ isSending: false });
        });
    }
  };

  /**
   * @param {boolean} pIsInShape
   */
  handleIsInShape = pIsInShape => {
    this.setState({ pointIsInShape: pIsInShape });
  };

  toggleOpen = e => {
    if (
      e.target === this.postWrapper.current ||
      e.target === this.openButton.current ||
      e.target === this.closeButton.current ||
      e.target.textContent === 'Abbrechen'
    ) {
      if (typeof this.props.onToggleOpenState === 'function') {
        this.props.onToggleOpenState(!this.state.open);
      }
      this.setState({ open: !this.state.open, errors: [], isSending: false });
    }
  };

  /**
   * @returns {number[]} dateArr
   */
  createOptionsList = () => {
    const currentYear = new Date().getFullYear() - 15;
    let dateArr = ['-- Bitte auswählen --'];

    for (let i = 1; i < 101; i++) {
      dateArr[i] = currentYear - i;
    }

    return dateArr;
  };

  showPreview = () => {
    if (!this.checkFormErrors()) {
      this.setState({ isSending: false });
      return;
    }

    this.setState({ showPreview: !this.state.showPreview });
  };

  render() {
    const { isSending } = this.state;

    return (
      <>
        <button
          onClick={() => {
            if (typeof this.props.onToggleOpenState === 'function') {
              this.props.onToggleOpenState(true);
            }
            this.setState({ open: true });
          }}
          ref={this.openButton}
          className={style.button}
          style={{
            color: this.props.textHighlightColor || '#000',
            backgroundColor: this.props.colorCode,
            width: this.props.sidebar === 'open' ? 'calc(50% - 19px)' : 'calc(25% - 29px)'
          }}
        >
          <div className={style.customBorder}>
            <span className={style.buttonText}>Jetzt ihr Angebot hinzufügen</span>
          </div>
        </button>
        {this.state.open && (
          <div className={`${style.postWrapper}`} onClick={this.toggleOpen} ref={this.postWrapper}>
            <div className={style.post} style={{ overflow: 'scroll' }}>
              <div className={style.closeRow}>
                <CloseButton ref={this.closeButton} />
              </div>
              <div className={style.contentwrapper}>
                <StyledScrollbars iOSHeight={'88%'}>
                  <Container modifier="contentWidthOverlay" style={{ paddingBottom: '65px' }}>
                    {this.props.content.map(({ type, ...rest }, i) => {
                      const pseudoId = `${type}-${i}`;

                      switch (type) {
                        case 'module-text':
                          return (
                            <section key={pseudoId}>
                              <ModuleText {...rest} />
                            </section>
                          );
                        case 'module-post-category':
                          return (
                            <section className={style.modulePostCategory} key={pseudoId}>
                              <Category
                                {...rest}
                                onChange={({ category, selectedCategory }) => {
                                  this.setState({
                                    category: category,
                                    selectedCategory,
                                    categoryObj: rest.items.find(({ name }) => name === category)
                                  });
                                }}
                                type={this.state.variant}
                                onCategoryTypeChange={type => {
                                  this.setState({
                                    // reset the category if the type changed
                                    category: null,
                                    variant: type
                                  });
                                }}
                                category={this.state.category}
                                selected={this.state.selectedCategory}
                              />
                            </section>
                          );
                        case 'module-post-title':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Title
                                {...rest}
                                inputTitle={this.state.title}
                                onChange={title => this.setState({ title })}
                              />
                            </section>
                          );
                        case 'module-post-tags':
                          return (
                            <section key={pseudoId}>
                              <Tags {...rest} onChange={tags => this.setState({ tags })} />
                            </section>
                          );
                        case 'module-post-description':
                          return (
                            <section key={pseudoId}>
                              <Description
                                {...rest}
                                onChange={content => this.setState({ content })}
                              />
                            </section>
                          );
                        case 'module-post-map':
                          const { map, ...props } = rest;

                          const center = this.state.coordinates
                            ? {
                                lng: this.state.coordinates.longitude,
                                lat: this.state.coordinates.latitude
                              }
                            : map && map.lat && map.lng
                            ? {
                                lng: map.lng,
                                lat: map.lat
                              }
                            : this.state.center
                            ? {
                                lng: this.state.center[1],
                                lat: this.state.center[0]
                              }
                            : null;

                          return (
                            <section key={pseudoId}>
                              <MapModule
                                {...props}
                                onChange={coordinates => this.setState({ coordinates })}
                                onChangeAddress={address => this.setState({ address })}
                                mapStyle={
                                  (map && map.style) ||
                                  'mapbox://styles/cheffen/cjyk5thfn07yu1dm0v66sfvbg'
                                }
                                marker={this.state.coordinates}
                                center={center}
                                zoom={map && map.zoom}
                                maxZoom={map && map.maxZoom}
                                minZoom={map && map.minZoom}
                                shape={this.props.shape}
                                handleIsInShape={this.handleIsInShape}
                                mapOnly={false}
                              />

                              {!this.state.pointIsInShape && (
                                <div
                                  style={{
                                    paddingLeft: 'calc(60% + 15px)',
                                    color: 'red',
                                    fontSize: 14
                                  }}
                                >
                                  Der Marker kann nur innerhalb des markierten Bereichs gesetzt
                                  werden.
                                </div>
                              )}
                            </section>
                          );
                        case 'module-post-image':
                          return (
                            <section key={pseudoId}>
                              <Image {...rest} onChange={image => this.setState({ image })} />
                            </section>
                          );
                        case 'module-post-image2':
                          return (
                            <section key={pseudoId}>
                              <Image {...rest} onChange={image2 => this.setState({ image2 })} />
                            </section>
                          );
                        case 'module-post-email':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Email
                                {...rest}
                                email={this.state.email}
                                onChange={email => this.setState({ email })}
                              />
                            </section>
                          );
                        case 'module-post-weblink':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Weblink
                                {...rest}
                                weblink={this.state.weblink}
                                onChange={weblink => this.setState({ weblink })}
                              />
                            </section>
                          );
                        case 'module-post-phone':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Phone
                                {...rest}
                                phone={this.state.phone}
                                onChange={phone => this.setState({ phone })}
                              />
                            </section>
                          );
                        case 'module-post-commentmail':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <CommentEmail
                                {...rest}
                                email={this.state.email}
                                onChange={commentmail => this.setState({ commentmail })}
                              />
                            </section>
                          );
                        case 'module-post-alias':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Alias
                                {...rest}
                                alias={this.state.alias}
                                onChange={alias => this.setState({ alias })}
                              />
                            </section>
                          );
                        case 'module-post-gender':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Gender
                                {...rest}
                                gender={this.state.gender}
                                onChange={gender => this.setState({ gender })}
                              />
                            </section>
                          );
                        case 'module-post-birthyear':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Birthyear
                                {...rest}
                                options={this.state.birthyears}
                                value={this.state.birthyear}
                                onChange={value => {
                                  this.setState({ birthyear: value });
                                }}
                              />
                            </section>
                          );
                        case 'module-post-city':
                          return (
                            <section key={pseudoId}>
                              <City
                                {...rest}
                                onChange={city => {
                                  this.setState({ city: city });
                                }}
                                value={this.state.city}
                                options={rest.dropdownValues}
                              />
                            </section>
                          );
                        default:
                          return (
                            <section key={pseudoId} style={{ color: 'red' }}>
                              No rendering definition for {type}
                            </section>
                          );
                      }
                    })}
                    <section
                      className={style.noBorder}
                      style={{ marginBottom: 50, textAlign: 'center' }}
                    >
                      <Recaptcha
                        style={{
                          display: 'inline-block'
                        }}
                        expiredCallback={() => {
                          this.setState({ captchaToken: null });
                        }}
                        verifyCallback={token => {
                          this.setState({ captchaToken: token });
                        }}
                      />
                      <div className={style.error}>
                        {this.state.errors.map((err, i) => {
                          return (
                            <Fragment key={i}>
                              <span>{err}</span>
                              <br />
                            </Fragment>
                          );
                        })}
                      </div>
                      {this.state.showPreview && (
                        <PostDetail
                          {...this.state}
                          modifier="preview"
                          coordinates={
                            Boolean(this.state.coordinates) ? this.state.coordinates : {}
                          }
                          onClose={() => this.setState({ showPreview: false })}
                          onSend={this.handleSend}
                          modal={true}
                        />
                      )}
                      <Button onClick={this.toggleOpen}>Abbrechen</Button>{' '}
                      <Button
                        onClick={this.showPreview}
                        modifier={isSending && 'loading'}
                        disabled={isSending}
                      >
                        {isSending && <Spinner />}Beitrag veröffentlichen
                      </Button>
                    </section>
                  </Container>
                </StyledScrollbars>
              </div>
            </div>
            {Boolean(this.state.showVerificationMessage) && (
              <PopupDialog
                onClose={() => {
                  this.setState({ showVerificationMessage: false, open: false }, () => {
                    this.props.onToggleOpenState(false);
                  });
                }}
                image={require('../images/mail.svg')}
              >
                <h2 style={{ fontWeight: 600, lineHeight: 1.3, fontSize: 28 }}>
                  Vielen Dank für Ihren Eintrag!
                </h2>
                <p>Ihr Eintrag wird in Kürze freigeschaltet.</p>
                <Button
                  onClick={() => {
                    this.setState({ showVerificationMessage: false, open: false }, () => {
                      this.props.onToggleOpenState(false);
                    });
                  }}
                  modifier="darker"
                >
                  OK
                </Button>
              </PopupDialog>
            )}
          </div>
        )}
      </>
    );
  }
}
