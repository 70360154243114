import React from 'react';

export const WsLogo = props => (
  <svg
    className="wsLogo"
    viewBox="52.35 543.509 314.83 41.826"
    width="314.83"
    height="41.826"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="ws-1"
      d="M 0 0 L 0 -14.695 C 0 -17.234 0.182 -18.993 0.545 -19.97 C 1.215 -21.756 2.609 -22.65 4.73 -22.65 C 6.154 -22.65 7.313 -22.161 8.205 -21.185 C 9.099 -20.207 9.545 -19.007 9.545 -17.583 L 9.545 0 L 13.732 0 L 13.732 -17.583 C 13.732 -18.979 14.172 -20.173 15.051 -21.163 C 15.93 -22.154 17.067 -22.65 18.463 -22.65 C 19.913 -22.65 21.065 -22.168 21.917 -21.205 C 22.768 -20.242 23.193 -19.035 23.193 -17.583 L 23.193 0 L 27.464 0 L 27.464 -17.291 C 27.464 -19.83 26.612 -21.987 24.91 -23.759 C 23.207 -25.532 21.101 -26.417 18.59 -26.417 C 15.715 -26.417 13.382 -25.287 11.598 -23.026 C 9.839 -25.287 7.536 -26.417 4.689 -26.417 C 2.148 -26.417 0.042 -25.538 -1.633 -23.779 C -3.308 -22.021 -4.145 -19.872 -4.145 -17.332 L -4.145 0 Z"
      transform="matrix(1, 0, 0, -1, 56.495099, 550.41825)"
    />
    <path
      className="ws-2"
      d="M 0 0 C -0.781 5.053 -3.936 7.579 -9.461 7.579 C -12.225 7.579 -14.605 6.644 -16.601 4.774 C -18.596 2.903 -19.58 0.629 -19.551 -2.05 C -19.523 -4.674 -18.582 -6.907 -16.725 -8.748 C -14.869 -10.591 -12.629 -11.511 -10.006 -11.511 C -7.773 -11.511 -5.652 -11.092 -3.643 -10.257 C -1.298 -9.251 0.195 -7.897 0.838 -6.195 L 3.643 -8.916 C 2.498 -11.065 0.67 -12.713 -1.843 -13.855 C -4.131 -14.944 -6.672 -15.489 -9.461 -15.489 C -13.369 -15.489 -16.712 -14.198 -19.488 -11.617 C -22.266 -9.035 -23.654 -5.846 -23.654 -2.05 C -23.654 1.719 -22.26 4.914 -19.467 7.537 C -16.676 10.16 -13.342 11.472 -9.461 11.472 C -5.275 11.444 -1.996 10.495 0.377 8.626 C 3.195 6.42 4.604 3 4.604 -1.631 C 4.604 -2.19 4.591 -2.776 4.564 -3.389 L -15.532 -3.389 L -15.532 -0.04 L -0.041 -0.04 Z"
      transform="matrix(1, 0, 0, -1, 111.213901, 561.513953)"
    />
    <path
      className="ws-3"
      d="M 0 0 L 5.944 0 C 8.625 0 10.87 0.823 12.686 2.47 C 14.499 4.116 15.406 6.253 15.406 8.876 C 15.406 11.526 14.478 13.746 12.624 15.532 C 10.767 17.318 8.499 18.211 5.82 18.211 L 0 18.211 Z M 19.803 8.876 C 19.803 5.136 18.47 2.016 15.806 -0.481 C 13.14 -2.979 9.923 -4.229 6.155 -4.229 L -4.062 -4.229 L -4.062 28.889 L 0 28.889 L 0 21.979 L 6.363 21.979 C 8.932 21.979 11.275 21.365 13.397 20.137 C 15.519 18.91 17.149 17.179 18.296 14.946 C 19.301 13.021 19.803 10.996 19.803 8.876"
      transform="matrix(1, 0, 0, -1, 123.228499, 572.397742)"
    />
    <path
      className="ws-4"
      d="M 0 0 L 0 4.354 L 17.877 4.354 C 18.909 4.354 19.803 4.71 20.557 5.422 C 21.31 6.134 21.688 7.005 21.688 8.038 C 21.688 9.07 21.316 9.942 20.577 10.655 C 19.837 11.367 18.937 11.724 17.877 11.724 L 7.033 11.724 C 4.214 11.724 2.106 12.909 0.711 15.281 C 0.041 16.425 -0.293 17.652 -0.293 18.965 C -0.293 20.947 0.411 22.649 1.82 24.073 C 3.229 25.496 4.939 26.208 6.949 26.208 L 25.162 26.208 L 25.162 21.979 L 7.11 21.979 C 6.247 21.979 5.508 21.685 4.896 21.094 C 4.283 20.505 3.977 19.786 3.977 18.944 C 3.977 18.073 4.283 17.342 4.896 16.752 C 5.51 16.162 6.247 15.867 7.11 15.867 L 18.143 15.867 C 21.318 15.867 23.658 14.541 25.164 11.891 C 25.859 10.662 26.208 9.35 26.208 7.956 C 26.208 5.61 25.404 3.698 23.801 2.219 C 22.195 0.739 20.234 0 17.918 0 Z"
      transform="matrix(1, 0, 0, -1, 146.547897, 576.626248)"
    />
    <path
      className="ws-5"
      d="M 0 0 C -2.373 2.567 -5.012 3.852 -7.914 3.852 C -10.51 3.852 -12.743 2.901 -14.611 1.005 C -16.482 -0.895 -17.418 -3.154 -17.418 -5.777 C -17.418 -8.373 -16.482 -10.6 -14.611 -12.456 C -12.743 -14.312 -10.51 -15.238 -7.914 -15.238 C -5.932 -15.238 -4.14 -14.709 -2.533 -13.647 C -0.929 -12.588 0.209 -11.151 0.878 -9.336 L 3.726 -12.057 C 1.186 -16.83 -2.499 -19.217 -7.328 -19.217 C -11.318 -19.217 -14.654 -17.989 -17.334 -15.532 C -20.125 -12.993 -21.521 -9.741 -21.521 -5.777 C -21.521 -1.954 -20.195 1.257 -17.543 3.852 C -14.891 6.446 -11.627 7.744 -7.746 7.744 C -2.974 7.744 0.711 5.917 3.307 2.261 Z"
      transform="matrix(1, 0, 0, -1, 196.954107, 557.786353)"
    />
    <path
      className="ws-6"
      d="M 0 0 L 0 -15.112 L -4.143 -15.112 L -4.143 -0.207 C -4.143 1.969 -4.844 3.812 -6.242 5.318 C -7.641 6.825 -9.434 7.579 -11.617 7.579 C -13.8 7.579 -15.591 6.832 -16.99 5.34 C -18.391 3.847 -19.089 1.997 -19.089 -0.207 L -19.089 -15.112 L -23.359 -15.112 L -23.359 18.005 L -19.089 18.005 L -19.089 8.375 C -17.691 9.464 -16.475 10.217 -15.438 10.635 C -14.181 11.193 -12.781 11.472 -11.244 11.472 C -8.11 11.472 -5.453 10.349 -3.271 8.104 C -1.09 5.855 0 3.154 0 0"
      transform="matrix(1, 0, 0, -1, 226.006802, 561.513953)"
    />
    <path
      className="ws-7"
      d="M 0 0 L 0 15.03 L 4.186 15.03 L 4.186 0.21 C 4.186 -1.968 4.883 -3.809 6.279 -5.316 C 7.675 -6.823 9.447 -7.578 11.596 -7.578 C 13.773 -7.578 15.567 -6.831 16.977 -5.337 C 18.385 -3.845 19.09 -2.009 19.09 0.168 L 19.09 15.03 L 23.361 15.03 L 23.361 -11.136 L 19.09 -11.136 L 19.09 -8.373 C 17.695 -9.46 16.48 -10.215 15.447 -10.633 C 14.191 -11.192 12.797 -11.471 11.262 -11.471 C 8.107 -11.471 5.443 -10.348 3.266 -8.101 C 1.088 -5.854 0 -3.154 0 0"
      transform="matrix(1, 0, 0, -1, 229.776415, 565.490455)"
    />
    <path
      className="ws-8"
      d="M 0 0 L 5.82 0 C 8.499 0 10.768 0.872 12.625 2.617 C 14.479 4.359 15.408 6.559 15.408 9.21 C 15.408 11.833 14.502 13.989 12.687 15.678 C 10.873 17.367 8.625 18.21 5.946 18.21 L 0 18.21 Z M 18.297 3.266 C 17.152 1.032 15.519 -0.705 13.399 -1.946 C 11.277 -3.19 8.934 -3.811 6.365 -3.811 L 0 -3.811 L 0 -12.519 L -4.061 -12.519 L -4.061 22.397 L 6.155 22.397 C 9.925 22.397 13.141 21.135 15.807 18.609 C 18.471 16.083 19.805 12.95 19.805 9.21 C 19.805 7.144 19.302 5.163 18.297 3.266"
      transform="matrix(1, 0, 0, -1, 261.299812, 572.815645)"
    />
    <path
      className="ws-9"
      d="M 0 0 L 5.821 0 C 8.499 0 10.767 0.872 12.624 2.617 C 14.481 4.359 15.407 6.559 15.407 9.21 C 15.407 11.833 14.501 13.989 12.687 15.678 C 10.872 17.367 8.624 18.21 5.945 18.21 L 0 18.21 Z M 18.296 3.266 C 17.152 1.032 15.519 -0.705 13.398 -1.946 C 11.276 -3.19 8.933 -3.811 6.364 -3.811 L 0 -3.811 L 0 -12.519 L -4.06 -12.519 L -4.06 22.397 L 6.155 22.397 C 9.924 22.397 13.14 21.135 15.806 18.609 C 18.47 16.083 19.804 12.95 19.804 9.21 C 19.804 7.144 19.302 5.163 18.296 3.266"
      transform="matrix(1, 0, 0, -1, 288.972706, 572.815645)"
    />
    <path
      className="ws-10"
      d="M 0 0 C -0.781 5.053 -3.936 7.579 -9.461 7.579 C -12.225 7.579 -14.604 6.644 -16.6 4.774 C -18.596 2.903 -19.578 0.629 -19.551 -2.05 C -19.523 -4.674 -18.582 -6.907 -16.725 -8.748 C -14.868 -10.591 -12.629 -11.511 -10.004 -11.511 C -7.773 -11.511 -5.65 -11.092 -3.641 -10.257 C -1.297 -9.251 0.196 -7.897 0.838 -6.195 L 3.643 -8.916 C 2.498 -11.065 0.671 -12.713 -1.842 -13.855 C -4.13 -14.944 -6.672 -15.489 -9.461 -15.489 C -13.368 -15.489 -16.711 -14.198 -19.488 -11.617 C -22.266 -9.035 -23.653 -5.846 -23.653 -2.05 C -23.653 1.719 -22.258 4.914 -19.466 7.537 C -16.675 10.16 -13.34 11.472 -9.461 11.472 C -5.275 11.444 -1.994 10.495 0.378 8.626 C 3.195 6.42 4.607 3 4.607 -1.631 C 4.607 -2.19 4.592 -2.776 4.564 -3.389 L -15.531 -3.389 L -15.531 -0.04 L -0.04 -0.04 Z"
      transform="matrix(1, 0, 0, -1, 335.862315, 561.513953)"
    />
    <path
      className="ws-11"
      d="M 0 0 L 0 -15.112 L -4.146 -15.112 L -4.146 -0.207 C -4.146 1.969 -4.846 3.812 -6.244 5.318 C -7.646 6.825 -9.436 7.579 -11.618 7.579 C -13.802 7.579 -15.593 6.832 -16.991 5.34 C -18.393 3.847 -19.092 1.997 -19.092 -0.207 L -19.092 -15.112 L -23.36 -15.112 L -23.36 11.096 L -19.092 11.096 L -19.092 8.375 C -17.692 9.464 -16.476 10.217 -15.442 10.635 C -14.183 11.193 -12.784 11.472 -11.245 11.472 C -8.113 11.472 -5.457 10.349 -3.274 8.104 C -1.092 5.855 0 3.154 0 0"
      transform="matrix(1, 0, 0, -1, 367.179698, 561.513953)"
    />
  </svg>
);
