import React from 'react';
import style from './togglebutton.module.scss';
import { OFFER_COLOR, WANT_COLOR } from '../../constants';

export function ToggleButton({
  onClick,
  selectedType,
  style: styles,
  unselectable = false,
  isOnPostCreatePage
}) {
  const selectedStyle = style.toggleSelected;
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: isOnPostCreatePage ? 'left' : 'space-around',
        paddingLeft: isOnPostCreatePage ? '90px' : '0',
        marginBottom: '50px',
        ...styles
      }}
    >
      <button
        className={
          style.toggleButton +
          (selectedType === '/offer' || selectedType === '/' ? ' ' + selectedStyle : '')
        }
        style={{
          marginRight: isOnPostCreatePage ? 30 : 12,
          backgroundColor: OFFER_COLOR
        }}
        onClick={() => {
          if (selectedType === '/offer') {
            if (!unselectable) return;

            return onClick('/');
          }
          onClick('/offer');
        }}
      >
        Biete
      </button>
      <button
        className={
          style.toggleButton +
          (selectedType === '/want' || selectedType === '/' ? ' ' + selectedStyle : '')
        }
        style={{ backgroundColor: WANT_COLOR }}
        onClick={() => {
          if (selectedType === '/want') {
            if (!unselectable) return;

            return onClick('/');
          }
          onClick('/want');
        }}
      >
        Suche
      </button>
    </div>
  );
}
