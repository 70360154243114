import React, { Component } from 'react';
import Layout from '../components/layout';
import { Link } from '@reach/router';
import { Sidebar } from '../components/sidebar';
import sidebarStyle from '../components/sidebar.module.scss';
import mappageStyle from './mappage.module.scss';
import { ArrowDown } from '../components/icons/arrow-down';
import stylesArrow from '../templates/variant-page.module.scss';
import { getPosts, likePost, blamePost, contactPost } from '../api';
import { MapboxMap } from '../components/mapbox-map';
import { Tile } from '../components/post-tile';
import tileStyle from '../components/tile.module.scss';
import { PostDetail } from '../components/post-detail';
import { Footer } from '../components/footer';
import filterStyle from '../components/filter.module.scss';
import { graphql } from 'gatsby';
import { Button } from '../components/button';
import { InfoLayer } from '../components/layer';

import withLocation from '../components/with-location';
import { validatePost, deletePost, setPostVisibility } from '../api/post';
import PopupDialog from '../components/popup-dialog';
import { UserContext } from '../context/UserContext';
import { Recaptcha } from '../components/recaptcha';
import SEO from '../components/seo';
import inside from 'point-in-polygon';
import { WindowSizeContext } from '../context/WindowSizeContext';
import { mobileMax } from '../context/WindowSizeContext';
import { transform } from 'lodash';
import { motion, AnimatePresence } from 'framer-motion';

let Marker, Cluster;

class PostList extends Component {
  // inputRef = React.createRef();

  constructor(props) {
    super(props);

    const data = props.data.allDataJson.edges[0].node;
    const postCreatePage = props.data.postCreatePage.edges[0].node;

    // use the post-create page to find the category module to determine all possible categories
    const categories = postCreatePage.content.find(module => module.type === 'module-post-category')
      .items;

    const categoryCount = {};
    for (const category of categories) {
      categoryCount[category.name] = 0;
    }

    this.state = {
      allPosts: [],
      posts: [],
      filters: [],
      suggestions: [],
      possibilities: [],
      error: undefined,
      openDetail: undefined,
      blamedPosts: new Set(),
      blamePost: undefined,
      contactPost: undefined,
      initial: true,
      hasFilterBeenUsed: false,
      createOpen: false,
      blameCaptchaToken: undefined,
      blameCaptchaError: undefined,
      contactCaptchaToken: undefined,
      contactCaptchaError: undefined,
      isSafari: false,
      hoveredMarker: undefined,
      updateMapCenter: false,
      updateZoom: false,
      interaction: true,
      sideBarState: 'closed',
      variant: '/',
      visiblePolygon: [],
      /** @type {[number, number]} */
      center: [data.map.lat, data.map.lng],
      /** @type {[number, number]} */
      centerStart: [data.map.lat, data.map.lng],
      /** @type {number} */
      zoom: data.map.zoom,
      mapStyle: data.map.styles[0],
      context: {
        ...data,
        categories: categories.reduce((acc, curr) => {
          acc[curr.name] = curr;
          return acc;
        }, {}),
        postCreateContent: postCreatePage.content
      },
      arrowVisible: true,
      contactSuccess: false,
      textFilterInput: '',
      categoryCount: categoryCount,
      loading: true,
      openLayer: false,
      filterOpen: false
    };
    this.textSearchRef = React.createRef();
    this.hoverTimeout = undefined;
  }

  componentDidMount() {
    document.querySelector('html').classList.add('noscroll-body');

    const ReactMapboxGl = require('react-mapbox-gl');
    Marker = ReactMapboxGl.Marker;
    Cluster = ReactMapboxGl.Cluster;

    if (this.props.search.delete !== undefined) {
      deletePost({ slug: this.props.search.slug, token: this.props.search.delete })
        .then(([res, err]) => {
          if (err !== null) {
            alert(`Ihr Beitrag ${res.title} wurde erfolgreich gelöscht.`);
          }
          this.getPostList();
        })
        .catch(console.error);
    } else if (this.props.search.token !== undefined) {
      validatePost({ slug: this.props.search.slug, token: this.props.search.token }).then(() => {
        this.getPostList();
      });
    } else {
      this.getPostList();
    }

    this.setState({
      isSafari:
        navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0
    });
  }

  componentWillUnmount() {
    document.querySelector('html').classList.remove('noscroll-body');
    clearTimeout(this.hoverTimeout);
  }

  toggleAllFilter = () => {
    let filters = [];

    // if (this.state.filters.length === 0) {
    filters = this.state.context.categories;
    // }

    const { posts, categoryCount } = this.applyFilter({
      allPosts: this.state.allPosts,
      visiblePolygon: this.state.visiblePolygon,
      textFilter: this.state.textFilterInput,
      filters: filters
    });

    // when the filter changes, we want to show the arrow pointing down again
    const isMobile = window.innerWidth <= mobileMax;
    let arrowVisible = this.state.arrowVisible;

    if (isMobile && filters.length !== this.state.filters.length) {
      arrowVisible = true;
    }

    this.setState({
      hasFilterBeenUsed: false,
      filters: filters,
      posts: posts,
      categoryCount,
      arrowVisible: arrowVisible
    });
  };

  toggleFilter = categoryName => {
    // when the filter changes, we want to show the arrow pointing down again
    const isMobile = window.innerWidth <= mobileMax;
    let arrowVisible = this.state.arrowVisible;

    if (this.state.hasFilterBeenUsed) {
      let filters = [...this.state.filters, categoryName];

      if (this.state.filters.includes(categoryName)) {
        filters = this.state.filters.filter(e => e !== categoryName);
      }
      const { posts, categoryCount } = this.applyFilter({
        allPosts: this.state.allPosts,
        visiblePolygon: this.state.visiblePolygon,
        textFilter: this.state.textFilterInput,
        filters: filters
      });

      if (isMobile) {
        arrowVisible = true;
      }

      this.setState({
        filters: filters,
        posts: posts,
        categoryCount: categoryCount,
        arrowVisible: arrowVisible
      });

      if (filters.length === 0) {
        this.toggleAllFilter();
      } else if (filters.length === Object.keys(this.state.context.categories).length) {
        this.setState({ hasFilterBeenUsed: false });
      }

      return;
    }

    const { posts, categoryCount } = this.applyFilter({
      allPosts: this.state.allPosts,
      visiblePolygon: this.state.visiblePolygon,
      textFilter: this.state.textFilterInput,
      filters: [categoryName]
    });

    if (isMobile) {
      arrowVisible = true;
    }

    this.setState({
      hasFilterBeenUsed: true,
      filters: [categoryName],
      posts: posts,
      categoryCount,
      arrowVisible: arrowVisible
    });
  };

  getPostList = () => {
    this.setState({
      updateZoom: true,
      updatePosition: true
    });

    getPosts({ variant: '/' }).then(([posts, error]) => {
      if (posts) {
        this.setState({
          filters: posts.filterCategories,
          suggestions: posts.suggestions,
          context: {
            ...this.state.context,
            categories: posts.filterCategories
          }
        });
        const { posts: filteredPosts, categoryCount } = this.applyFilter({
          allPosts: posts.items,
          visiblePolygon: this.state.visiblePolygon,
          textFilter: this.state.textFilterInput,
          filters: this.state.filters
        });
        this.setState({
          allPosts: posts.items,
          posts: filteredPosts,
          categoryCount: categoryCount,
          error,
          loading: false
        });
        if (this.state.openDetail) {
          this.setState({ openDetail: this.state.posts[this.state.openIndex] });
        }

        if (this.props.search.slug !== undefined && this.state.initial === true) {
          const post = posts.items.find(({ slug }) => slug === this.props.search.slug);

          if (Boolean(post)) {
            this.setState({ openDetail: post, initial: false });
          }
        }
      }
    });
  };

  hoverMarker = slug => event => {
    clearTimeout(this.hoverTimeout);
    this.setState({ hoveredMarker: slug });
    this.hoverTimeout = setTimeout(() => {
      // TODO: move this into state instead of doing it by hand
      const element = document.getElementById(`list-${slug}`);
      if (element) {
        element.style.outline = '2px solid #fff';
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }, 1000);
  };

  hoverEndMarker = slug => event => {
    this.setState({ hoveredMarker: null });
    clearTimeout(this.hoverTimeout);
    const element = document.getElementById(`list-${slug}`);
    if (element) {
      element.style.outline = 'none';
    }
  };

  openDetail = (item, index, interaction) => e => {
    if (e.target.classList && e.target.classList.contains('likeButton')) {
      if (interaction) this.likePost({ slug: item.slug });
    } else {
      this.setState({ openDetail: item });
      this.setState({ openIndex: index });
    }
    this.setState({ interaction: interaction });
  };

  likePost = ({ slug }) => {
    likePost({ slug: slug }).then(([post, error]) => {
      if (!error) {
        this.getPostList();
      }
    });
  };

  blamePost = ({ slug }) => {
    this.setState({ blamePost: slug });
  };

  contactPost = ({ slug }) => {
    this.setState({ contactPost: slug });
  };

  submitBlame = event => {
    event.preventDefault();

    const reasonField = event.target.querySelector('[name="reason"]');
    const emailField = event.target.querySelector('[name="email"]');

    const formData = new FormData(event.target);

    if (this.state.blameCaptchaToken === null) {
      this.setState({
        blameCaptchaError: 'Das Captcha ist abgelaufen. Bitte benutzen Sie es erneut.'
      });
      return;
    } else if (this.state.blameCaptchaToken === undefined) {
      this.setState({
        blameCaptchaError: 'Bitte benutzen Sie das Captcha-Element.'
      });
      return;
    }

    if (!Boolean(formData.get('reason'))) {
      reasonField.classList.add('error');
      return;
    }

    const sendData = {
      slug: this.state.blamePost,
      email: formData.get('email'),
      reason: formData.get('reason'),
      captchaToken: this.state.blameCaptchaToken
    };

    this.setState({ isBlaming: true, blameCaptchaError: undefined });

    blamePost(sendData)
      .then(([post, error]) => {
        if (!error) {
          reasonField.classList.remove('error');
          reasonField.value = '';
          emailField.value = '';

          const blamedPosts = this.state.blamedPosts;
          blamedPosts.add(this.state.blamePost);
          this.setState({
            blamedPosts: blamedPosts,
            blamePost: undefined,
            isBlaming: undefined,
            blameCaptchaToken: undefined,
            blameCaptchaError: undefined
          });
        } else {
          this.setState({
            blameCaptchaError: error.message,
            isBlaming: undefined
          });
        }
      })
      .catch(console.error);
  };

  submitContact = event => {
    event.preventDefault();

    const messageBodyField = event.target.querySelector('[name="messageBody"]');
    const emailField = event.target.querySelector('[name="email"]');
    const phoneField = event.target.querySelector('[name="phone"]');

    const formData = new FormData(event.target);

    if (this.state.contactCaptchaToken === null) {
      this.setState({
        contactCaptchaError: 'Das Captcha ist abgelaufen. Benutz es bitte erneut.'
      });
      return;
    } else if (this.state.contactCaptchaToken === undefined) {
      this.setState({
        contactCaptchaError: 'Benutz bitte das Captcha-Element.'
      });
      return;
    }

    if (!Boolean(formData.get('messageBody'))) {
      messageBodyField.classList.add('error');
      return;
    }

    const sendData = {
      slug: this.state.contactPost,
      email: formData.get('email'),
      phone: formData.get('phone'),
      messageBody: formData.get('messageBody'),
      captchaToken: this.state.contactCaptchaToken
    };

    this.setState({ isContacting: true, blameCaptchaError: undefined });

    contactPost(sendData)
      .then(([post, error]) => {
        if (!error) {
          messageBodyField.classList.remove('error');
          messageBodyField.value = '';
          emailField.value = '';
          phoneField.value = '';

          this.setState({
            contactPost: undefined,
            isContacting: undefined,
            contactCaptchaToken: undefined,
            contactCaptchaError: undefined,
            contactSuccess: true
          });
        } else {
          this.setState({
            contactCaptchaError: error.message,
            isContacting: undefined
          });
        }
      })
      .catch(console.error);
  };

  toggleVisibility = (item, user) => {
    if (user.loggedIn && user.roles.includes('ROLE_ADMIN')) {
      setPostVisibility({
        slug: item.slug,
        visible: !item.visible
      }).then(([post, error]) => {
        if (!error) {
          this.getPostList();
        }
      });
    }
  };

  changeFilterVisibility = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
  };

  applyFilter = ({ allPosts, visiblePolygon, textFilter, filters }) => {
    // create a new categoryCount object
    const categoryCount = { ...this.state.categoryCount };
    for (const key in categoryCount) {
      categoryCount[key] = 0;
    }

    const posts = allPosts.filter(post => {
      if (!this.hasCoordinates(post)) {
        return false;
      }

      if (!this.isVisibleByTextInput(post, textFilter)) {
        return false;
      }

      if (!this.isVisibleInMap(post, visiblePolygon)) {
        return false;
      }

      // add +1 to the category for each post which would be visible and the text filter matches
      categoryCount[post.category] += 1;

      if (!this.isVisibleByCategory(post, filters)) {
        return false;
      }

      return true;
    });
    return { posts, categoryCount };
  };

  hasCoordinates = post => {
    return (
      !!post.coordinates && post.coordinates.latitude && post.coordinates.longitude && post.visible
    );
  };

  isVisibleByCategory = (post, filters) => {
    const hasFilter = post.filterCategories.find(filter => {
      return filters.includes(filter);
    });
    return hasFilter !== undefined;
  };

  isVisibleInMap = (post, polygon) => {
    if (polygon.length === 0) return true;

    const { latitude, longitude } = post.coordinates;

    return inside([longitude, latitude], polygon);
  };

  isVisibleByTextInput = (post, textFilter) => {
    if (textFilter === '') {
      return true;
    }

    if (!Array.isArray(post.professions)) {
      post.professions = [];
    }

    const inputTextLower = textFilter.toLowerCase();
    const lowerProfessions = post.professions
      .map(name => name.toLowerCase())
      .find(name => name.includes(inputTextLower));

    if (lowerProfessions && lowerProfessions.length > 0) {
      // console.log({ lowerProfessions: lowerProfessions, title: post.title });
    }

    return post
      ? (post.content ? post.content.toLowerCase().includes(inputTextLower) : false) ||
          (post.address && post.address.city
            ? post.address.city.toLowerCase().includes(inputTextLower)
            : false) ||
          (post.address && post.address.street
            ? post.address.street.toLowerCase().includes(inputTextLower)
            : false) ||
          (post.address && post.address.zip
            ? post.address.zip.toLowerCase().includes(inputTextLower)
            : false) ||
          (post.title ? post.title.toLowerCase().includes(inputTextLower) : false) ||
          (lowerProfessions && lowerProfessions.length > 0)
      : false;
  };

  onTextFilterInput = e => {
    const filterText = e.target.value;
    let possibilities = [];

    if (filterText.length > 2) {
      possibilities = this.state.suggestions.filter(suggestion =>
        suggestion.toLowerCase().includes(filterText.toLowerCase())
      );
    }

    possibilities =
      possibilities.length > 1
        ? possibilities
        : possibilities.length === 1 && possibilities[0].toLowerCase() === filterText.toLowerCase()
        ? []
        : possibilities;

    const { posts, categoryCount } = this.applyFilter({
      allPosts: this.state.allPosts,
      visiblePolygon: this.state.visiblePolygon,
      textFilter: filterText,
      filters: this.state.filters
    });

    this.setState({
      textFilterInput: filterText,
      posts: posts,
      categoryCount: categoryCount,
      possibilities: possibilities.slice(0, 15)
    });
  };

  onVisibleAreaChanged = ({ zoom, center, polygon }) => {
    const newState = {};

    if (polygon && JSON.stringify(this.state.visiblePolygon) !== JSON.stringify(polygon)) {
      const { posts, categoryCount } = this.applyFilter({
        allPosts: this.state.allPosts,
        visiblePolygon: polygon,
        textFilter: this.state.textFilterInput,
        filters: this.state.filters
      });

      newState.categoryCount = categoryCount;
      newState.visiblePolygon = polygon;
      newState.posts = posts;
    }

    if (zoom && zoom !== this.state.zoom) {
      newState.zoom = zoom;
    }

    if (center && center[0] !== this.state.center[0] && center[1] !== this.state.center[1]) {
      newState.center = center;
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState, () => {
        // fixme
        this.forceUpdate();
      });
    }
  };

  clusterMarker = (coordinates, label = 'C') => {
    return (
      <Marker
        coordinates={coordinates}
        key={`marker-${coordinates[0]}-${coordinates[1]}`}
        // style={styles.clusterMarker}
        style={{
          width: 35,
          height: 35,
          borderRadius: '50%',
          background: '#F15A25',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          fontSize: 16,
          fontWeight: 'bold'
        }}
      ></Marker>
    );
  };

  render() {
    const {
      colorCode,
      textHighlightColor,
      title,
      map,
      mapFeatures: shape,
      categories,
      postCreateContent
    } = this.state.context;

    const markers = {};
    const noFilters = this.state.filters.length === 0;

    const mapModule = postCreateContent.find(function(el) {
      return el.type === 'module-post-map';
    });

    const { isSafari, center, zoom, mapStyle, arrowVisible } = this.state;
    return (
      <Layout>
        <SEO title={title} />
        <WindowSizeContext.Consumer>
          {({ viewport, showMapH }) => {
            return (
              <>
                <span className={showMapH ? '' : 'mapNotVisible'}>
                  <MapboxMap
                    inputRef={this.inputRef}
                    center={center}
                    zoom={zoom}
                    minZoom={viewport === 'desktop' ? map.minZoom : 4}
                    maxZoom={map.maxZoom}
                    maxBounds={
                      viewport === 'desktop'
                        ? map.maxBounds
                        : viewport === 'tablet'
                        ? map.maxBoundsTablet
                        : map.maxBoundsMobile
                    }
                    onMapStyleChange={link => {
                      this.setState({ mapStyle: map.styles.find(e => e.link === link) });
                    }}
                    mapStyle={mapStyle}
                    mapFeatures={shape}
                    loading={!(this.state.posts.length && this.state.posts.length > 0)}
                    width={'100%'}
                    height={showMapH ? (viewport === 'mobile' ? '280px' : '100%') : 0}
                    // updateZoom={this.state.updateZoom}
                    // updateZoom={false}
                    // updatePosition={this.state.updateMapCenter}
                    style={{
                      width: viewport === 'mobile' ? '100%' : viewport === 'tablet' ? '50%' : '60%'
                    }}
                    onVisibleAreaChanged={this.onVisibleAreaChanged}
                    onResult={this.onResult}
                  >
                    {Cluster && (
                      <Cluster
                        ClusterMarkerFactory={this.clusterMarker}
                        style={{ color: 'red' }}
                        zoomOnClick={true}
                      >
                        {this.state.posts.map(item => {
                          const marker = (
                            <Marker
                              anchor={'bottom'}
                              coordinates={[item.coordinates.longitude, item.coordinates.latitude]}
                              onMouseEnter={
                                viewport === 'desktop' ? this.hoverMarker(item.slug) : () => {}
                              }
                              onMouseLeave={this.hoverEndMarker(item.slug)}
                              key={`marker-${item.slug}`}
                              onClick={this.openDetail(item, undefined, true)}
                              style={{
                                cursor: 'pointer',
                                opacity: 1,
                                filter: isSafari ? 'none' : `grayscale(${item.visible ? 0 : 1})`
                              }}
                              className={
                                this.state.hoveredMarker === item.slug ? 'active-marker' : ''
                              }
                            >
                              <img
                                src={require(`../images/marker-${
                                  this.state.hoveredMarker === item.slug ? 'hover' : 'default'
                                }.png`)}
                                alt="Marker"
                                style={{ width: 30, height: 'auto' }}
                              />
                            </Marker>
                          );
                          markers[item.slug] = marker;

                          return marker;
                        })}
                      </Cluster>
                    )}
                  </MapboxMap>
                </span>
                <MapSidebar
                  onScroll={() => {
                    this.setState({ arrowVisible: false });
                  }}
                  className={this.state.createOpen ? sidebarStyle.forceOverflow : undefined}
                  onSidebarStateChange={currentSideBarState => {
                    this.setState({ sideBarState: currentSideBarState });
                  }}
                  arrowVisible={arrowVisible}
                  onFilterToggle={this.toggleFilter}
                  onChangeFilterVisibility={this.changeFilterVisibility}
                  onAllFilterToggle={e => {
                    e.preventDefault();
                    this.toggleAllFilter();
                  }}
                  allPosts={this.state.allPosts}
                  posts={this.state.posts}
                  filters={this.state.filters}
                  geocoderInputRef={this.inputRef}
                  noFilters={noFilters}
                  categories={categories}
                  isSafari={isSafari}
                  postCreateContent={postCreateContent}
                  shape={shape}
                  textHighlightColor={textHighlightColor}
                  colorCode={colorCode}
                  map={map}
                  onPostCreated={this.getPostList}
                  onPostCreateOpen={open => this.setState({ createOpen: open })}
                  onTileClickFactory={this.openDetail}
                  likePost={this.likePost}
                  toggleVisibility={this.toggleVisibility}
                  onTileMouseOver={slug => {
                    this.setState({ updateZoom: false, hoveredMarker: slug });
                  }}
                  onTileMouseOut={() => {
                    this.setState({ hoveredMarker: undefined });
                  }}
                  textFilterInput={this.state.textFilterInput}
                  onTextFilterInput={this.onTextFilterInput}
                  categoryCount={this.state.categoryCount}
                  filterOpen={this.state.filterOpen}
                  loading={this.state.loading}
                  textSearchRef={this.textSearchRef}
                  showMapH={showMapH}
                  viewport={viewport}
                  onLayerToggle={type => {
                    this.setState({ openLayer: type });
                  }}
                  possibilities={this.state.possibilities}
                />
                {this.state
                  .openDetail /* && categories[this.state.openDetail.category] !== undefined */ && (
                  <PostDetail
                    {...this.state.openDetail}
                    categoryObj={categories[this.state.openDetail.category]}
                    onClose={() => {
                      this.setState({ openDetail: undefined });
                    }}
                    interaction={this.state.interaction}
                    onLike={() => {
                      this.likePost({ slug: this.state.openDetail.slug });
                    }}
                    onBlame={() => {
                      this.blamePost({ slug: this.state.openDetail.slug });
                    }}
                    onContact={() => {
                      this.contactPost({ slug: this.state.openDetail.slug });
                    }}
                    blamed={this.state.blamedPosts.has(this.state.openDetail.slug)}
                    map={{
                      style:
                        (mapModule && mapModule.map && mapModule.map.style) ||
                        'mapbox://styles/cheffen/cjyk5thfn07yu1dm0v66sfvbg'
                    }}
                  />
                )}
                {this.state.openLayer && (
                  <InfoLayer
                    type={this.state.openLayer}
                    style={{
                      right: viewport === 'desktop' ? '40%' : 0,
                      top: showMapH ? (viewport === 'mobile' ? 280 : 0) : 50,
                      height: showMapH
                        ? viewport === 'mobile'
                          ? 'calc(100% - 280px - 33px)'
                          : viewport === 'tablet'
                          ? 'calc(100% - 34px)'
                          : 'auto'
                        : '100%'
                    }}
                    onClose={() => {
                      this.setState({ openLayer: false });
                    }}
                  />
                )}
                {Boolean(this.state.blamePost) && (
                  <PopupDialog
                    onClose={() =>
                      this.setState({
                        blamePost: undefined,
                        isBlaming: undefined,
                        blameCaptchaToken: undefined,
                        blameCaptchaError: undefined
                      })
                    }
                    image={require('../images/mail.svg')}
                  >
                    <h2>Diesen Beitrag Melden</h2>
                    <form onSubmit={this.submitBlame}>
                      <label>Warum melden?</label>
                      <textarea name="reason" />
                      <label>E-Mail-Adresse</label>
                      <input type="text" name="email" />
                      <Recaptcha
                        style={{
                          display: 'inline-block',
                          width: '100%'
                        }}
                        expiredCallback={() => {
                          this.setState({ blameCaptchaToken: null });
                        }}
                        verifyCallback={token => {
                          this.setState({ blameCaptchaToken: token });
                        }}
                      />
                      {this.state.blameCaptchaError && (
                        <span style={{ color: 'red', display: 'block' }}>
                          {this.state.blameCaptchaError}
                        </span>
                      )}
                      <Button
                        modifier={this.state.isBlaming && 'loading'}
                        disabled={this.state.isBlaming}
                      >
                        Absenden
                      </Button>
                    </form>
                  </PopupDialog>
                )}
                {this.state.contactSuccess && (
                  <PopupDialog
                    onClose={() => {
                      this.setState({ contactSuccess: false });
                    }}
                  >
                    <h2>Vielen Dank!</h2>
                    Deine Nachricht wurde dem Beitragsersteller übermittelt.
                  </PopupDialog>
                )}
                {Boolean(this.state.contactPost) && (
                  <PopupDialog
                    onClose={() =>
                      this.setState({
                        contactPost: undefined,
                        isContacting: undefined,
                        contactCaptchaToken: undefined,
                        contactCaptchaError: undefined
                      })
                    }
                    image={require('../images/mail.svg')}
                  >
                    <h2>Den Beitragsersteller kontaktieren</h2>
                    <form onSubmit={this.submitContact}>
                      <label>Sende eine Nachricht*</label>
                      <textarea name="messageBody" required />
                      <label>Deine E-Mailadresse*</label>
                      <input type="text" name="email" required />
                      <label>Deine Telefonnummer</label>
                      <input type="text" name="phone" />
                      <label style={{ display: 'flex', marginBottom: '20px' }}>
                        <span
                          style={{
                            display: 'inline-block',
                            margin: '0 25px 0 0'
                          }}
                        >
                          <input
                            style={{ minWidth: '20px' }}
                            type="checkbox"
                            name="dataSec"
                            required
                          />
                        </span>
                        <span>
                          Ich habe die{' '}
                          <Link to="/sicherheitshinweise">Hinweise und Vorsichtsmaßnahmen</Link>{' '}
                          gelesen.*
                          <br />
                          <br />
                          Mit dem Absenden dieser Nachricht erklärst Du dich mit den{' '}
                          <Link to="/imprint">AGB</Link> und den{' '}
                          <Link to="/imprint">Nettiquetten</Link> dieser Plattform einverstanden.
                          Wir weisen explizit darauf hin, dass der/die Empfängerin dieser Nachricht
                          Deine E-Mail-Adresse sehen wird und Dir antworten kann.
                        </span>
                      </label>
                      <Recaptcha
                        style={{
                          display: 'inline-block',
                          width: '100%'
                        }}
                        expiredCallback={() => {
                          this.setState({ contactCaptchaToken: null });
                        }}
                        verifyCallback={token => {
                          this.setState({ contactCaptchaToken: token });
                        }}
                      />
                      {this.state.contactCaptchaError && (
                        <span style={{ color: 'red', display: 'block' }}>
                          {this.state.contactCaptchaError}
                        </span>
                      )}
                      <Button
                        modifier={this.state.isContacting && 'loading'}
                        disabled={this.state.isContacting}
                      >
                        Absenden
                      </Button>
                    </form>
                  </PopupDialog>
                )}
                <Footer fixed={true} />
              </>
            );
          }}
        </WindowSizeContext.Consumer>
      </Layout>
    );
  }
}

function MapSidebar({
  onScroll,
  className,
  onSidebarStateChange,
  arrowVisible,
  onFilterToggle,
  onAllFilterToggle,
  posts,
  filters,
  noFilters,
  categories,
  isSafari,
  textHighlightColor,
  colorCode,
  onTileClickFactory,
  likePost,
  toggleVisibility,
  onTileMouseOver,
  onTileMouseOut,
  textFilterInput,
  onTextFilterInput,
  categoryCount,
  filterOpen,
  onChangeFilterVisibility,
  loading,
  textSearchRef,
  showMapH,
  possibilities
}) {
  return (
    <Sidebar
      onScroll={onScroll}
      className={`${className} ${showMapH ? '' : 'sidebarMapNotVisible'}`}
      onSidebarStateChange={onSidebarStateChange}
      render={currentSideBarState => {
        const baseTileStyle = `${tileStyle.tile} ${tileStyle[currentSideBarState]}`;
        return loading ? (
          <div className={'loader'} style={{ marginTop: 100 }}></div>
        ) : (
          <>
            {arrowVisible && <ArrowDown className={stylesArrow.arrowDown} />}
            <h2 className={`${mappageStyle.introText} ${filterStyle.allText}`}>
              Finde deinen Ausbildungsplatz
            </h2>
            <br className={mappageStyle.filterBreak} />
            <br className={mappageStyle.filterBreak} />
            {/* <div className={filterStyle.all} onClick={onAllFilterToggle}>
              <div className={filterStyle.allCount}>
                {posts && posts.length >= 0 ? posts.length : <div className={'loader'}></div>}
              </div>
              <br className={mappageStyle.filterBreak} />
              <div className={filterStyle.allText}>Angebote verfügbar</div>
            </div> */}
            <form
              onSubmit={e => {
                textSearchRef.current.blur();
                e.preventDefault();
              }}
            >
              <div className={mappageStyle.inputWrapper}>
                <svg viewBox="0 0 18 18" xmlSpace="preserve" width="18" height="18">
                  <path d="M7.4 2.5c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9c1 0 1.8-.2 2.5-.8l3.7 3.7c.2.2.4.3.8.3.7 0 1.1-.4 1.1-1.1 0-.3-.1-.5-.3-.8L11.4 10c.4-.8.8-1.6.8-2.5.1-2.8-2.1-5-4.8-5zm0 1.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.3-1.3-3.3-3.1 1.4-3.3 3.3-3.3z"></path>
                </svg>
                <input
                  type="search"
                  onChange={onTextFilterInput}
                  value={textFilterInput}
                  placeholder="Jetzt Angebot suchen"
                  ref={textSearchRef}
                ></input>
                {possibilities.length > 0 && (
                  <ul className={mappageStyle.suggestion}>
                    {possibilities.map(name => (
                      <li key={name} onClick={() => onTextFilterInput({ target: { value: name } })}>
                        {name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </form>
            <CategoryFilter
              noFilters={noFilters}
              categories={categories}
              filters={filters}
              onCategoryToggle={onFilterToggle}
              onCategoryToggleAll={onAllFilterToggle}
              colorCode={colorCode}
              textHighlightColor={textHighlightColor}
              posts={posts}
              categoryCount={categoryCount}
              open={filterOpen}
              onChangeFilterVisibility={onChangeFilterVisibility}
            />
            <div key={'post-tile-wrapper'} className={tileStyle.tileWrapper}>
              <UserContext.Consumer>
                {user => {
                  const showPostCreate = true;
                  const isAdmin = user.loggedIn && user.roles.includes('ROLE_ADMIN');

                  return posts.map((item, index) => {
                    const id = `list-${item.slug}`;
                    return (
                      <Tile
                        variant={item.variant}
                        key={id}
                        id={id}
                        post={item}
                        className={
                          baseTileStyle + (item.visible || isSafari ? '' : ` ${tileStyle.hidden}`)
                        }
                        onClick={onTileClickFactory(item, index, showPostCreate)}
                        category={categories[item.category]}
                        like={showPostCreate ? () => likePost({ slug: item.slug }) : () => {}}
                        toggleVisibility={() => toggleVisibility(item, user)}
                        isAdmin={isAdmin}
                        onMouseEnter={() => {
                          onTileMouseOver(item.slug);
                        }}
                        onMouseLeave={onTileMouseOut}
                        interaction={showPostCreate}
                      />
                    );
                  });
                }}
              </UserContext.Consumer>
            </div>
          </>
        );
      }}
    />
  );
}

function CategoryFilter({
  noFilters,
  categories,
  filters,
  onCategoryToggle,
  categoryCount,
  open,
  onChangeFilterVisibility
}) {
  return (
    <>
      <AnimatePresence initial={true}>
        <motion.ul
          className={filterStyle.list}
          initial="collapsed"
          animate={open ? 'open' : 'collapsed'}
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 1, height: 129 }
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {categories.map(categoryName => {
            const active = noFilters ? false : filters.includes(categoryName);
            return (
              <li
                key={categoryName}
                className={`${filterStyle.listItem}${active ? ` ${filterStyle.active}` : ''}`}
              >
                <div
                  onClick={e => {
                    e.preventDefault();
                    onCategoryToggle(categoryName);
                  }}
                  className={filterStyle.category}
                >
                  <div
                    className={filterStyle.iconBubble}
                    style={{
                      backgroundColor: active ? 'white' : 'rgba(255, 255, 255, 0.6)'
                    }}
                  >
                    <div className={filterStyle.title}>{categoryName}</div>
                  </div>
                </div>
              </li>
            );
          })}
        </motion.ul>
      </AnimatePresence>
      <button
        onClick={e => {
          e.preventDefault();
          onChangeFilterVisibility();
        }}
        className={`${filterStyle.toogleButton} ${open ? filterStyle.open : filterStyle.collapsed}`}
      >
        Filter {open ? 'verstecken' : 'anzeigen'}
        <motion.div
          initial={{ scale: 0 }}
          animate={{
            scale: [1, 2, 2, 1, 1]
          }}
          transition={{
            duration: 1,
            loop: 1
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 20 20"
            fill="none"
            className={filterStyle.toogleButtonIcon}
            style={{ transform: `scaleY(${open ? '-1' : '1'})` }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.70711 7.29289L10 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
              fill="#ffffff"
            />
          </svg>
        </motion.div>
      </button>
    </>
  );
}

export default withLocation(PostList);

export const query = graphql`
  query {
    allDataJson(filter: { page: { eq: "map" } }) {
      edges {
        node {
          title
          subTitle
          colorCode
          textHighlightColor
          map {
            lat
            lng
            maxBounds
            maxBoundsTablet
            maxBoundsMobile
            minZoom
            maxZoom
            zoom
            styles {
              link
              icon
              title
            }
          }
          mapFeatures {
            fillProperties {
              fill_color
              fill_opacity
            }
            shape
          }
        }
      }
    }

    postCreatePage: allDataJson(filter: { name: { eq: "post-create" } }) {
      edges {
        node {
          name
          content {
            type
            title
            required
            maxchars
            info
            placeholder
            sections {
              text
            }
            dropdownValues
            items {
              name
              image {
                file
              }
              marker {
                file
                activeFile
              }
              description {
                text
              }
            }
            map {
              style
              lat
              lng
              minZoom
              maxZoom
              zoom
            }
          }
        }
      }
    }
  }
`;
