import React from 'react';
import { isFunction } from 'util';
import { Info } from './info';

import postCreateStyle from '../../components/post-create.module.scss';

/**
 *
 * @param {object} props
 */
const Phone = props => {
  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  const onChange = event => {
    if (isFunction(props.onChange)) props.onChange(event.target.value);
  };

  const { title, required, phone, info, placeholder } = props;

  return (
    <>
      <h2>
        {!required && <span className={postCreateStyle.optionalTag}>optional</span>}
        {title}
      </h2>
      <div className={postCreateStyle.sectionLeft}>
        <input
          name="phone"
          type="text"
          placeholder={placeholder}
          value={phone}
          onChange={onChange}
          required={true}
        />
      </div>
      <div className={postCreateStyle.sectionRight}>
        <Info text={info} />
      </div>
    </>
  );
};

export { Phone };
