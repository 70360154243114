import React from 'react';
import style from '../components/tile.module.scss';
import postStyle from '../components/post-tile.module.scss';
import { format } from 'date-fns';

/**
 * @param {object} param0
 * @param {() => void} param0.toggleVisibility
 * @param {boolean} param0.isAdmin
 */
export const Tile = ({
  post,
  like,
  category,
  className,
  toggleVisibility,
  isAdmin,
  interaction,
  variant,
  ...rest
}) => {
  const { image, title, content, visible, eventDetails } = post;
  let date = null;

  if (eventDetails && eventDetails.checkInDay) {
    date = new Date(eventDetails.checkInDay);
  }
  return (
    <div {...rest} className={`${className} ${postStyle.postTile} ${image ? '' : style.noImage}`}>
      {isAdmin && (
        <span
          style={{ position: 'absolute', left: 5, top: 5, zIndex: 100 }}
          onClick={event => {
            event.stopPropagation();
            toggleVisibility();
          }}
        >
          {visible ? 'ausblenden' : 'anzeigen'}
        </span>
      )}
      <div className={postStyle.tileImage}>
        <figure
          style={{
            paddingBottom: '100%',
            position: 'relative',
            margin: 0,
            marginTop: '40px',
            width: '100%'
          }}
        >
          {image && (
            <img
              alt={title}
              src={`${image}`}
              style={{
                position: 'absolute',
                top: '26%',
                left: '50%',
                maxWidth: '90%',
                width: 'auto',
                height: 'auto',
                maxHeight: '90%'
              }}
              loading="lazy"
            />
          )}
        </figure>
        {eventDetails && (
          <span className={postStyle.eventDetails}>
            {eventDetails.city} am {format(new Date(date), 'dd.MM.yyyy')}
          </span>
        )}
      </div>

      <div className={style.content}>
        <h2>{title.length > 40 ? `${title.slice(0, 40)} ...` : title}</h2>

        <p className={style.description} dangerouslySetInnerHTML={{ __html: content }}></p>
      </div>
    </div>
  );
};
