import React, { Component, Fragment } from 'react';
import { navigate } from '@reach/router';
import { Container } from '../components/container';
import style from '../components/post-create.module.scss';
import StyledScrollbars from '../components/styled-scrollbars';
import { Button } from '../components/button';
import { Spinner } from '../components/spinner';

import { Category } from '../modules/post/category';
import { Title } from '../modules/post/title';
import { Tags } from '../modules/post/tags';
import { Description } from '../modules/post/description';
import { MapModule } from '../modules/post/map';
import { Image } from '../modules/post/image';
import { Email } from '../modules/post/email';
import { Phone } from '../modules/post/phone';
import { Weblink } from '../modules/post/weblink';
import { CommentEmail } from '../modules/post/comment-email';
import { Alias } from '../modules/post/alias';

import { uploadImage } from '../api';
import { Gender } from '../modules/post/gender';
import { Birthyear } from '../modules/post/birthyear';
import { City } from '../modules/post/city';
import { PostDetail } from '../components/post-detail';
import PopupDialog from '../components/popup-dialog';
import { Recaptcha } from '../components/recaptcha';
import { graphql } from 'gatsby';

import { getPost, patchPost } from '../api';

const defaultState = {
  slug: undefined,
  token: undefined,
  postLoaded: false,
  open: true,
  isSending: false,
  errors: [],
  coordinates: null,
  address: undefined,
  title: '',
  content: null,
  center: null,
  category: null,
  variant: '/offer',
  tags: [],
  image: null,
  image2: null,
  email: '',
  weblink: '',
  phone: '',
  alias: '',
  gender: null,
  birthyear: undefined,
  city: undefined,
  pointIsInShape: true,
  captchaToken: undefined,
  showVerificationMessage: false,
  showPreview: false,
  map: undefined,
  commentmail: undefined,
  postFetchError: undefined
};
class Redeem extends Component {
  constructor(props) {
    super(props);
    const postCreatePage = props.data.postCreatePage.edges[0].node;
    this.postWrapper = React.createRef();
    this.openButton = React.createRef();
    this.closeButton = React.createRef();
    defaultState.center = this.props.center || null;

    const birthyears = this.createOptionsList();

    this.state = {
      ...defaultState,
      birthyears: birthyears,
      birthyear: birthyears[0],
      postCreatePageContent: postCreatePage.content
    };
  }

  /**
   * @return {boolean} indicator, whether errors exist
   */
  checkFormErrors = () => {
    let errors = [];

    for (const item of this.state.postCreatePageContent) {
      if (item.required) {
        switch (item.type) {
          case 'module-post-category':
            if (!Boolean(this.state.category)) {
              errors.push('Bitte wählen Sie eine Kategorie.');
            }
            break;
          case 'module-post-title':
            if (!Boolean(this.state.title)) {
              errors.push('Bitte geben Sie einen Titel an.');
            }
            break;
          case 'module-post-tags':
            if (this.state.tags.length === 0) {
              errors.push('Bitte wählen Sie mindestens ein Thema aus.');
            }
            break;
          case 'module-post-description':
            if (!Boolean(this.state.content)) {
              errors.push('Bitte geben Sie eine Beschreibung an.');
            }
            break;
          case 'module-post-map':
            if (!Boolean(this.state.coordinates)) {
              errors.push('Bitte geben Sie Ihre Adresse an.');
            }
            break;
          case 'module-post-image':
            if (!Boolean(this.state.image)) {
              errors.push('Bitte laden Sie ein Bild hoch.');
            }
            break;
          case 'module-post-image2':
            if (!Boolean(this.state.image2)) {
              errors.push('Bitte laden Sie ein Bild hoch.');
            }
            break;
          case 'module-post-email':
            if (!Boolean(this.state.email)) {
              errors.push('Bitte geben Sie Ihre E-Mail Adresse an.');
            }
            break;
          case 'module-post-alias':
            if (!Boolean(this.state.alias)) {
              errors.push('Bitte geben Sie einen Alias an.');
            }
            break;
          case 'module-post-gender':
            if (!Boolean(this.state.gender)) {
              errors.push('Bitte geben Sie Ihr Geschlecht an.');
            }
            break;
          case 'module-post-birthyear':
            if (!Boolean(this.state.birthyear)) {
              errors.push('Bitte geben Sie Ihr Geburtsjahr an.');
            }
            break;
          case 'module-post-city':
            if (!Boolean(this.state.city)) {
              errors.push('Bitte geben Sie Ihren Wohnort an.');
            }
            break;
          default:
            break;
        }
      }
    }

    if (this.state.captchaToken === null) {
      errors.push('Das Captcha ist abgelaufen. Versuchen Sie es bitte erneut.');
    } else if (this.state.captchaToken === undefined) {
      errors.push('Bitte benutzen Sie das Captcha-Element.');
    }

    if (errors.length > 0) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: [] });
    return true;
  };

  finishSend = ([post, error]) => {
    if (post) {
      return navigate(`/map?slug=${this.state.slug}`);
    }

    this.setState({ errors: [error.message], isSending: false });
  };

  sendToImageUpload = () => {
    const images = [];
    if (this.state.image) images.push(uploadImage({ file: this.state.image, mode: 'post' }));
    if (this.state.image2) images.push(uploadImage({ file: this.state.image2, mode: 'post' }));

    return Promise.all(images).then(results => {
      const returnObj = {
        image: null,
        image2: null
      };

      for (let index in results) {
        if (parseInt(index) === 0) {
          returnObj.image = results[index][0].fileName || this.state.image || this.state.image2;
        } else {
          returnObj.image2 = results[index][0].fileName || this.state.image2;
        }
      }

      return returnObj;
    });
  };

  handleSend = () => {
    this.setState({ isSending: true, errors: [] });

    if (!this.checkFormErrors()) {
      this.setState({ isSending: false });
      return;
    }

    if (this.state.image || this.state.image2) {
      this.sendToImageUpload().then(uris => {
        patchPost({ ...this.state, ...uris })
          .then(([post, error]) => {
            this.finishSend([post, error]);
          })
          .catch(err => {
            console.error(err);
            this.setState({ isSending: false });
          });
      });
    } else {
      patchPost({ ...this.state, image: null, image2: null })
        .then(([post, error]) => {
          this.finishSend([post, error]);
        })
        .catch(err => {
          console.error(err);
          this.setState({ isSending: false });
        });
    }
  };

  /**
   * @param {boolean} pIsInShape
   */
  handleIsInShape = pIsInShape => {
    this.setState({ pointIsInShape: pIsInShape });
  };

  toggleOpen = e => {
    if (
      e.target === this.postWrapper.current ||
      e.target === this.openButton.current ||
      e.target === this.closeButton.current ||
      e.target.textContent === 'Abbrechen'
    ) {
      if (typeof this.props.onToggleOpenState === 'function') {
        this.props.onToggleOpenState(!this.state.open);
      }
      this.setState({ open: !this.state.open, errors: [], isSending: false });
    }
  };

  /**
   * @returns {number[]} dateArr
   */
  createOptionsList = () => {
    const currentYear = new Date().getFullYear() - 15;
    let dateArr = ['-- Bitte auswählen --'];

    for (let i = 1; i < 101; i++) {
      dateArr[i] = currentYear - i;
    }

    return dateArr;
  };

  showPreview = () => {
    if (!this.checkFormErrors()) {
      this.setState({ isSending: false });
      return;
    }

    this.setState({ showPreview: !this.state.showPreview });
  };

  fetchPost = slug => {
    getPost({ slug }).then(([post, error]) => {
      if (error) {
        this.setState({
          postFetchError: (error && error.message) || 'Ein unbekannter Fehler ist aufgetreten.'
        });
        return;
      }
      if (post) {
        this.setState({ ...post, postLoaded: true });
      }
    });
  };

  componentDidMount() {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      const slug = urlParams.get('slug');
      if (token && slug) {
        this.setState({ token: token });
        this.fetchPost(slug);
        return;
      }
    }
    this.cancel();
  }

  cancel = () => {
    navigate(`/map`);
  };

  render() {
    const { isSending, postFetchError } = this.state;

    if (postFetchError) {
      return (
        <PopupDialog
          onClose={() => {
            navigate('/map');
          }}
        >
          {postFetchError}
        </PopupDialog>
      );
    }

    return !this.state.postLoaded ? (
      <div className={'loader loader-blue'} style={{ marginTop: 100 }}></div>
    ) : (
      <>
        {this.state.postLoaded && (
          <div className={`${style.postWrapper}`} ref={this.postWrapper}>
            <div className={`${style.post} ${style.postPatch}`} style={{ overflow: 'scroll' }}>
              <div className={`${style.contentwrapper} ${style.contentwrapperPatch}`}>
                <StyledScrollbars iOSHeight={'100%'}>
                  <Container modifier="contentWidthOverlay" style={{ paddingBottom: '65px' }}>
                    <section key={'headline-edit'}>
                      <h2>Beitrag anpassen</h2>
                    </section>
                    {this.state.postCreatePageContent.map(({ type, ...rest }, i) => {
                      const pseudoId = `${type}-${i}`;
                      switch (type) {
                        case 'module-text':
                          return null;
                        case 'module-post-category':
                          return (
                            <section className={style.modulePostCategory} key={pseudoId}>
                              <Category
                                {...rest}
                                onChange={({ category, selectedCategory }) => {
                                  this.setState({
                                    category: category || this.state.category,
                                    selectedCategory,
                                    categoryObj: rest.items.find(({ name }) => name === category)
                                  });
                                }}
                                type={this.state.variant}
                                onCategoryTypeChange={type => {
                                  this.setState({
                                    // reset the category if the type changed
                                    category: this.state.category,
                                    variant: type
                                  });
                                }}
                                category={this.state.category}
                                selected={this.state.selectedCategory}
                              />
                            </section>
                          );
                        case 'module-post-title':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Title
                                {...rest}
                                inputTitle={this.state.title}
                                onChange={title => this.setState({ title })}
                              />
                            </section>
                          );
                        case 'module-post-tags':
                          return (
                            <section key={pseudoId}>
                              <Tags {...rest} onChange={tags => this.setState({ tags })} />
                            </section>
                          );
                        case 'module-post-description':
                          return (
                            <section key={pseudoId}>
                              <Description
                                {...rest}
                                value={this.state.content}
                                onChange={content => this.setState({ content })}
                              />
                            </section>
                          );
                        case 'module-post-map':
                          const { map, ...props } = rest;

                          const center = this.state.coordinates
                            ? {
                                lng: this.state.coordinates.longitude,
                                lat: this.state.coordinates.latitude
                              }
                            : map && map.lat && map.lng
                            ? {
                                lng: map.lng,
                                lat: map.lat
                              }
                            : this.state.center
                            ? {
                                lng: this.state.center[1],
                                lat: this.state.center[0]
                              }
                            : null;

                          return (
                            <section key={pseudoId}>
                              <MapModule
                                {...props}
                                onChange={coordinates => this.setState({ coordinates })}
                                onChangeAddress={address => this.setState({ address })}
                                mapStyle={
                                  (map && map.style) ||
                                  'mapbox://styles/cheffen/cjyk5thfn07yu1dm0v66sfvbg'
                                }
                                marker={this.state.coordinates}
                                center={center}
                                zoom={map && map.zoom}
                                maxZoom={map && map.maxZoom}
                                minZoom={map && map.minZoom}
                                shape={this.props.shape}
                                handleIsInShape={this.handleIsInShape}
                                mapOnly={false}
                              />

                              {!this.state.pointIsInShape && (
                                <div
                                  style={{
                                    paddingLeft: 'calc(60% + 15px)',
                                    color: 'red',
                                    fontSize: 14
                                  }}
                                >
                                  Der Marker kann nur innerhalb des markierten Bereichs gesetzt
                                  werden.
                                </div>
                              )}
                            </section>
                          );
                        case 'module-post-image':
                          return (
                            <section key={pseudoId}>
                              <Image
                                {...rest}
                                currentFile={this.state.image}
                                onChange={image => this.setState({ image })}
                              />
                            </section>
                          );
                        case 'module-post-image2':
                          return (
                            <section key={pseudoId}>
                              <Image
                                {...rest}
                                currentFile={this.state.image2}
                                onChange={image2 => this.setState({ image2 })}
                              />
                            </section>
                          );
                        case 'module-post-email':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Email
                                {...rest}
                                email={this.state.email}
                                onChange={email => this.setState({ email })}
                              />
                            </section>
                          );
                        case 'module-post-weblink':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Weblink
                                {...rest}
                                weblink={this.state.weblink}
                                onChange={weblink => this.setState({ weblink })}
                              />
                            </section>
                          );
                        case 'module-post-phone':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Phone
                                {...rest}
                                phone={this.state.phone}
                                onChange={phone => this.setState({ phone })}
                              />
                            </section>
                          );
                        case 'module-post-commentmail':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <CommentEmail
                                {...rest}
                                email={this.state.email}
                                onChange={commentmail => this.setState({ commentmail })}
                              />
                            </section>
                          );
                        case 'module-post-alias':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Alias
                                {...rest}
                                alias={this.state.alias}
                                onChange={alias => this.setState({ alias })}
                              />
                            </section>
                          );
                        case 'module-post-gender':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Gender
                                {...rest}
                                gender={this.state.gender}
                                onChange={gender => this.setState({ gender })}
                              />
                            </section>
                          );
                        case 'module-post-birthyear':
                          return (
                            <section key={pseudoId} className={style.noBorder}>
                              <Birthyear
                                {...rest}
                                options={this.state.birthyears}
                                value={this.state.birthyear}
                                onChange={value => {
                                  this.setState({ birthyear: value });
                                }}
                              />
                            </section>
                          );
                        case 'module-post-city':
                          return (
                            <section key={pseudoId}>
                              <City
                                {...rest}
                                onChange={city => {
                                  this.setState({ city: city });
                                }}
                                value={this.state.city}
                                options={rest.dropdownValues}
                              />
                            </section>
                          );
                        default:
                          return (
                            <section key={pseudoId} style={{ color: 'red' }}>
                              No rendering definition for {type}
                            </section>
                          );
                      }
                    })}
                    <section
                      className={style.noBorder}
                      style={{ marginBottom: 50, textAlign: 'center' }}
                    >
                      <Recaptcha
                        style={{
                          display: 'inline-block'
                        }}
                        expiredCallback={() => {
                          this.setState({ captchaToken: null });
                        }}
                        verifyCallback={token => {
                          this.setState({ captchaToken: token });
                        }}
                      />
                      <div className={style.error}>
                        {this.state.errors.map((err, i) => {
                          return (
                            <Fragment key={i}>
                              <span>{err}</span>
                              <br />
                            </Fragment>
                          );
                        })}
                      </div>
                      {this.state.showPreview && (
                        <PostDetail
                          {...this.state}
                          modifier="preview"
                          coordinates={
                            Boolean(this.state.coordinates) ? this.state.coordinates : {}
                          }
                          onClose={() => this.setState({ showPreview: false })}
                          onSend={this.handleSend}
                          modal={true}
                          patch={true}
                        />
                      )}
                      <Button onClick={this.cancel}>Abbrechen</Button>{' '}
                      <Button
                        onClick={this.showPreview}
                        modifier={isSending && 'loading'}
                        disabled={isSending}
                      >
                        {isSending && <Spinner />}Beitrag speichern
                      </Button>
                    </section>
                  </Container>
                </StyledScrollbars>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Redeem;

export const query = graphql`
  query {
    postCreatePage: allDataJson(filter: { name: { eq: "post-create" } }) {
      edges {
        node {
          name
          content {
            type
            title
            required
            maxchars
            info
            placeholder
            sections {
              text
            }
            dropdownValues
            items {
              name
              image {
                file
              }
              marker {
                file
                activeFile
              }
              description {
                text
              }
            }
            map {
              style
              lat
              lng
              minZoom
              maxZoom
              zoom
            }
          }
        }
      }
    }
  }
`;
