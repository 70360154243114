import React from 'react';

export const PkLogo = props => (
  <svg className="pkLogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196.67 21.96" {...props}>
    <defs></defs>
    <title>Element 2</title>
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <path className="cls-1" d="M35.31,17.71l-1.66-2.87-.43-.75a11,11,0,1,0-1.86,3.62h4Z" />
        <path
          className="cls-2"
          d="M11.08,11A11.52,11.52,0,0,1,13.42,4a9,9,0,0,0-3.17-.59,8.92,8.92,0,0,0-8.92,8.92,8.78,8.78,0,0,0,.37,2.52l-.35.61L0,17.83H3.21a8.93,8.93,0,0,0,12,2A11.59,11.59,0,0,1,11.08,11Z"
        />
        <path
          className="cls-3"
          d="M41.19,6.82a.3.3,0,0,1,.3-.31h3.8a3.61,3.61,0,1,1,0,7.21h-2v3.8a.32.32,0,0,1-.31.3H41.49a.29.29,0,0,1-.3-.3Zm4,4.93a1.67,1.67,0,0,0,1.67-1.68A1.62,1.62,0,0,0,45.16,8.5H43.29v3.25Z"
        />
        <path
          className="cls-3"
          d="M51.56,6.82a.31.31,0,0,1,.31-.31h1.49a.32.32,0,0,1,.3.31v9.06h4.12a.31.31,0,0,1,.31.31v1.33a.3.3,0,0,1-.31.3H51.87a.3.3,0,0,1-.31-.3Z"
        />
        <path
          className="cls-3"
          d="M59.58,17.4l5-10.87a.29.29,0,0,1,.28-.18H65a.28.28,0,0,1,.28.18l5,10.87a.28.28,0,0,1-.27.42H68.61a.47.47,0,0,1-.47-.32l-.79-1.75H62.52l-.79,1.75a.49.49,0,0,1-.47.32H59.85A.28.28,0,0,1,59.58,17.4Zm7-3.44-1.62-3.55h0L63.31,14Z"
        />
        <path
          className="cls-3"
          d="M72.57,6.64a.31.31,0,0,1,.31-.29h.4L80,13.49h0V6.82a.31.31,0,0,1,.31-.31h1.49a.32.32,0,0,1,.3.31V17.69a.3.3,0,0,1-.3.29h-.39l-6.76-7.41h0v7a.3.3,0,0,1-.31.3H72.88a.32.32,0,0,1-.31-.3Z"
        />
        <path
          className="cls-3"
          d="M85.28,10.48a1.16,1.16,0,1,1,1.16,1.16A1.15,1.15,0,0,1,85.28,10.48Zm1.16,3.3a1.16,1.16,0,1,1-1.16,1.16A1.15,1.15,0,0,1,86.44,13.78Z"
        />
        <path
          className="cls-3"
          d="M90.89,6.9a.38.38,0,0,1,.38-.39H92.7a.39.39,0,0,1,.38.39v4.36l4.08-4.59a.37.37,0,0,1,.3-.16h1.62a.34.34,0,0,1,.26.58l-4.22,4.75,4.52,5.42a.35.35,0,0,1-.29.56H97.59a.34.34,0,0,1-.29-.11l-4.22-5.2v4.92a.39.39,0,0,1-.38.39H91.27a.38.38,0,0,1-.38-.39Z"
        />
        <path
          className="cls-3"
          d="M107,6.35a5.82,5.82,0,1,1-5.8,5.83A5.81,5.81,0,0,1,107,6.35Zm0,9.53a3.72,3.72,0,1,0-3.7-3.7A3.72,3.72,0,0,0,107,15.88Z"
        />
        <path
          className="cls-3"
          d="M120.69,6.35a5.82,5.82,0,1,1-5.8,5.83A5.81,5.81,0,0,1,120.69,6.35Zm0,9.53a3.72,3.72,0,1,0-3.7-3.7A3.72,3.72,0,0,0,120.69,15.88Z"
        />
        <path
          className="cls-3"
          d="M129.43,6.82a.31.31,0,0,1,.31-.31h3.8a3.61,3.61,0,1,1,0,7.21h-2v3.8a.31.31,0,0,1-.3.3h-1.49a.3.3,0,0,1-.31-.3Zm4,4.93a1.67,1.67,0,0,0,1.66-1.68,1.61,1.61,0,0,0-1.66-1.57h-1.88v3.25Z"
        />
        <path
          className="cls-3"
          d="M139.81,6.82a.31.31,0,0,1,.31-.31h6.57a.31.31,0,0,1,.31.31V8.14a.31.31,0,0,1-.31.31h-4.78V11.1h4a.33.33,0,0,1,.31.31v1.34a.31.31,0,0,1-.31.31h-4v2.82h4.78a.31.31,0,0,1,.31.31v1.33a.3.3,0,0,1-.31.3h-6.57a.3.3,0,0,1-.31-.3Z"
        />
        <path
          className="cls-3"
          d="M150.12,6.82a.31.31,0,0,1,.31-.31h4.62A3.5,3.5,0,0,1,158.57,10a3.56,3.56,0,0,1-2.39,3.26l2.21,4.1a.3.3,0,0,1-.27.47h-1.7a.3.3,0,0,1-.26-.14L154,13.39h-1.8v4.13a.31.31,0,0,1-.31.3h-1.48a.3.3,0,0,1-.31-.3Zm4.75,4.85a1.65,1.65,0,0,0,1.6-1.65,1.61,1.61,0,0,0-1.6-1.57h-2.63v3.22Z"
        />
        <path
          className="cls-3"
          d="M160.22,17.4l5-10.87a.29.29,0,0,1,.27-.18h.16a.27.27,0,0,1,.28.18l5,10.87a.29.29,0,0,1-.28.42h-1.4a.45.45,0,0,1-.47-.32L168,15.75h-4.84l-.79,1.75a.48.48,0,0,1-.47.32h-1.4A.29.29,0,0,1,160.22,17.4Zm7-3.44-1.61-3.55h-.05L164,14Z"
        />
        <path
          className="cls-3"
          d="M174.14,8.45h-2.47a.31.31,0,0,1-.31-.31V6.82a.31.31,0,0,1,.31-.31h7.06a.3.3,0,0,1,.3.31V8.14a.3.3,0,0,1-.3.31h-2.47v9.07a.32.32,0,0,1-.31.3h-1.5a.31.31,0,0,1-.31-.3Z"
        />
        <path
          className="cls-3"
          d="M181.52,6.82a.33.33,0,0,1,.31-.31h1.5a.32.32,0,0,1,.31.31v10.7a.31.31,0,0,1-.31.3h-1.5a.32.32,0,0,1-.31-.3Z"
        />
        <path
          className="cls-3"
          d="M185.92,6.93a.28.28,0,0,1,.28-.42h1.64a.3.3,0,0,1,.28.18l3.1,7h.11l3.11-6.95a.3.3,0,0,1,.27-.18h1.65a.28.28,0,0,1,.27.42l-5,10.88a.3.3,0,0,1-.28.17h-.16a.3.3,0,0,1-.28-.17Z"
        />
      </g>
    </g>
  </svg>
);
