import React, { Component, Fragment } from 'react';
import { Link, graphql } from 'gatsby';

import { ModuleText } from '../components/module-text';
import { ModuleProcess } from '../components/module-process';
import { ModuleSlider } from '../components/module-slider';
import SEO from '../components/seo';
import { Button } from '../components/button';
import Layout from '../components/layout';
import StyledScrollbars from '../components/styled-scrollbars';
import { Container } from '../components/container';
import { Footer } from '../components/footer';
import { ModuleTextImage } from '../components/module-text-image';
import { ArrowDown } from '../components/icons/arrow-down';

import stylesArrow from '../templates/variant-page.module.scss';
import { FlipClock } from '../components/flip-clock';

class StartPage extends Component {
  constructor(props) {
    super(props);

    this.state = { isArrowVisible: true, isMobile: false };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onScroll = event => {
    if (event.target.scrollTop > 90) {
      this.setState({ isArrowVisible: false });
    }
  };

  onResize = () => {
    this.setState({ isMobile: window.innerWidth < 981 ? true : false });
  };

  render() {
    const { data } = this.props;
    const { isArrowVisible } = this.state;

    return (
      <Layout modifier="start">
        <StyledScrollbars onScroll={isArrowVisible ? this.onScroll : () => {}}>
          <div style={{ position: 'relative', minHeight: '100%', paddingBottom: '170px' }}>
            <SEO title="Startseite" />

            {isArrowVisible && (
              <ArrowDown
                className={stylesArrow.arrowDown}
                style={{
                  width: '180px',
                  height: '180px',
                  position: 'fixed',
                  bottom: '30px',
                  right: '80px',
                  zIndex: '10'
                }}
              />
            )}

            {data.allDataJson.edges[0].node.content.map(({ type, ...props }, index) => {
              switch (type) {
                case 'module-text':
                  return (
                    <Container
                      key={type + props.title}
                      modifier="contentWidth"
                      style={{ marginBottom: '150px' }}
                    >
                      <ModuleText key={type + props.title} {...props} />
                    </Container>
                  );
                case 'module-start-button':
                  return (
                    <div style={{ textAlign: 'center', marginTop: '50px', marginBottom: '50px' }}>
                      <Link to="/map">
                        <Button modifier={'bigRoundPlusButton'}>
                          <span>Start</span>
                        </Button>
                      </Link>
                      {index > 0 ? (
                        <hr style={{ margin: '100px 0 180px 0' }} />
                      ) : (
                        <hr style={{ margin: '100px 0 35px 0' }} />
                      )}
                    </div>
                  );
                case 'module-process':
                  return (
                    <Container
                      key={type + props.title}
                      modifier="contentWidth"
                      style={{ marginBottom: '150px' }}
                    >
                      <ModuleProcess key={type + props.title} {...props} />
                    </Container>
                  );
                case 'module-slider':
                  return (
                    <Fragment key={type + props.title}>
                      <hr style={{ margin: '195px 0 35px 0' }} />
                      <Container modifier="contentWidth">
                        <ModuleSlider {...props} />
                      </Container>
                      <hr style={{ margin: '35px 0 180px 0' }} />
                    </Fragment>
                  );
                case 'module-text-image':
                  return (
                    <Fragment key={type + props.title}>
                      {index > 0 && <hr style={{ margin: '195px 0 35px 0' }} />}
                      <Container modifier="contentWidth">
                        <ModuleTextImage {...props} modifier="start" />
                      </Container>
                    </Fragment>
                  );
                case 'module-countdown':
                  return (
                    <Fragment key={type + props.title}>
                      <Container modifier="contentWidth">
                        <FlipClock {...props} />
                      </Container>
                      <hr style={{ margin: '35px 0 180px 0' }} />
                    </Fragment>
                  );
                default:
                  console.warn(`Unhandled case ${type}`);
              }
              return null;
            })}
            <Footer fixed={false} />
          </div>
        </StyledScrollbars>
      </Layout>
    );
  }
}

export default StartPage;

export const query = graphql`
  query {
    allDataJson(filter: { page: { eq: "start" } }) {
      edges {
        node {
          content {
            type
            title
            text
            image {
              file
              gallery {
                type
                thumbnailSmall
                thumbnailLarge
                videoLink
              }
            }
            endDate
            sections {
              image {
                file
              }
              text
              type
              link
            }
          }
        }
      }
    }
  }
`;
